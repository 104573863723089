import {
  Button,
  Flex,
  FormControl,
  Link,
  Text,
  useToast,
} from '@chakra-ui/react'
import { Form, Formik } from 'formik'
import { useEffect, useRef, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router'
import { EmailField, PasswordField, TextField } from 'src/lib/form/fields'
import { HorizontalSeparator } from 'src/lib/separator'
import API from 'src/util/api'
import Config from 'src/util/config'

const checkRequiredErrors = (fieldsName, values, errors) =>
  fieldsName.reduce((prevVal, currentValue) => {
    prevVal[currentValue] = !values[currentValue] ? 'Required' : ''
    return prevVal
  }, errors)

const RegistrationForm = () => {
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)

  const history = useHistory()
  let search = useLocation().search
  search = new URLSearchParams(search)
  const toast = useToast()
  const onSignInClick = () => {
    history.push('/login')
  }
  const randomPwd =
    search.get('username') && search.get('email')
      ? (Math.random() + 1).toString(36).substring(5)
      : ''
  useEffect(() => {
    if (search.get('username') && search.get('email')) {
      console.log('SENT_NEW_USER_REQUEST')
      sentRequest(
        search.get('username'),
        search.get('email'),
        randomPwd,
        () => {},
        search.get('lang')
      )
    }
  }, [search])

  const sentRequest = async (name, email, password, setSubmitting, lang) => {
    try {
      const response = await API.post(Config.API_URI + 'newsignup', {
        email,
        name,
        password,
        isNotValidated: true,
        url: window.location.origin,
        registrationSubscriptions: lang,
      })

      if (response) {
        const { token } = response
        window.sessionStorage.setItem('token', token)
        history.push('/language')
        setSubmitting(false)
      }
    } catch (error) {
      toast({
        status: 'warning',
        title: error.data?.errors[0]?.msg || error || 'Error',
        position: 'bottom-left',
        isClosable: true,
      })
    }
  }
  return (
    <Flex flexDirection="column">
      <Formik
        initialValues={{
          name: search.get('username') || '',
          email: search.get('email') || '',
          password: randomPwd,
          confirmPassword: randomPwd,
        }}
        validate={values => {
          const errors = checkRequiredErrors(
            ['name', 'email', 'password', 'confirmPassword'],
            values,
            {}
          )

          if (!values.name.match('^[a-z A-Z]{3,30}$')) {
            errors.name =
              'Only alphabets are allowed with minimum 3 characters and maximum of 30 characters'
          }

          if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
            errors.email = 'Invalid email address'
          }

          if (values.password !== values.confirmPassword) {
            errors.password = 'Password and confirm password does not match'
          }

          const hasAnyError =
            Object.values(errors).filter(key => !!key).length > 0

          return hasAnyError ? errors : null
        }}
        onSubmit={({ name, email, password, ...values }, { setSubmitting }) => {
          sentRequest(name, email, password, setSubmitting)
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <Flex
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              mt={0.5}
              mb={7}
            >
              <Text fontSize="md" fontWeight="bold">
                Sign up to Langistan
              </Text>
              <Text fontSize="xs">Create your account here</Text>
            </Flex>
            <Flex gap={18} flexDirection="column">
              <FormControl>
                <TextField name="name" placeholder="Name" />
              </FormControl>
              <FormControl>
                <EmailField name="email" placeholder="Email" />
              </FormControl>
              <PasswordField
                name="password"
                placeholder="Password"
                value={showPassword}
                onChange={setShowPassword}
              />
              <PasswordField
                name="confirmPassword"
                placeholder="Confirm Password"
                value={showConfirmPassword}
                onChange={setShowConfirmPassword}
              />
              <Button
                type="submit"
                isLoading={isSubmitting}
                loadingText="Submitting"
                variant="primary"
                width="100%"
                style={{
                  height: '48px',
                }}
              >
                SignUp
              </Button>

              {/* <CustomButton
                noFocus
                background="#4285F4"
                _hover={{ background: '#1369f7' }}
                type="submit"
                isLoading={isSubmitting}
                loadingText="Submitting"
              >
                Sign up
              </CustomButton> */}
              <HorizontalSeparator />
              <Text textAlign="center">
                Already have an account?
                <Link
                  marginLeft={1}
                  color="#3E4CCE"
                  href="#"
                  onClick={onSignInClick}
                >
                  Sign in
                </Link>
              </Text>
            </Flex>
          </Form>
        )}
      </Formik>
    </Flex>
  )
}

export default RegistrationForm
