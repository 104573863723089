import {
  Button,
  Input,
  InputGroup,
  InputLeftAddon,
  Stack,
  Text,
} from '@chakra-ui/react'
import moment from 'moment'
import React from 'react'
import { useHistory } from 'react-router'
import { useUser } from 'src/hooks/useUser'
import {
  useRzpSubscription,
  useRzpSubscriptionActions,
} from 'src/redux/useRzpSubscriptionStore'

export default function RzpUserSubscription() {
  const subscription = useRzpSubscription()
  const { user } = useUser()
  const { fetchSubsciption } = useRzpSubscriptionActions()
  const history = useHistory()

  React.useEffect(() => {
    if (user?.rzpSubscriptionId) {
      fetchSubsciption(user.rzpSubscriptionId)
    }
  }, [user?.rzpSubscriptionId, fetchSubsciption])

  if (!subscription) {
    return <Text>Fetching subscription details...</Text>
  }

  const plan = subscription?.plan?.item

  return (
    <Stack gap="1">
      <InputGroup>
        <InputLeftAddon
          p={0}
          justifyContent="center"
          children="Plan Name"
          w={'180px'}
        />
        <Input readOnly value={plan.name || 'Free Plan'} />
      </InputGroup>
      <InputGroup>
        <InputLeftAddon
          p={0}
          justifyContent="center"
          children="From Date"
          w={'180px'}
        />
        <Input
          readOnly
          placeholder="From Date"
          value={
            subscription?.current_start &&
            `${moment(subscription.current_start * 1000).format('DD-MMM-YYYY')}`
          }
        />
      </InputGroup>
      <InputGroup>
        <InputLeftAddon
          p={0}
          justifyContent="center"
          children="To Date"
          w={'180px'}
        />
        <Input
          readOnly
          placeholder="To Date"
          value={
            subscription?.current_end &&
            `${moment(subscription.current_end * 1000).format('DD-MMM-YYYY')}`
          }
        />
      </InputGroup>
      {subscription ? (
        <Button
          width={'unset'}
          mx="auto"
          variant={'primary'}
          onClick={() => alert('Coming soon, please contact support')}
        >
          Manage Subscription
        </Button>
      ) : (
        <Button
          width={'unset'}
          mx="auto"
          variant={'primary'}
          onClick={() => history.push('/pricing')}
        >
          Upgrade Plan
        </Button>
        // <div>{JSON.stringify(user)}</div>
      )}
    </Stack>
  )
}
