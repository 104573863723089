import { useState } from 'react'
import { useHistory } from 'react-router'
import sectionthreeone from 'src/asset/images/sectionthree/sectionthreeone.jpg'
import sectionthreethree from 'src/asset/images/sectionthree/sectionthreethree.jpg'
import sectionthreetwo from 'src/asset/images/sectionthree/sectionthreetwo.jpg'

import { isMobile } from '../CourseDetail/Practice/Util'
import ImageSlider from './ImageSlider'
import {
  btnHover,
  fontMain,
  fontSecond,
  hyperTextColor,
  imageFlex,
  imagelg,
  imagemd,
  imageSx,
  textPanelWidth,
} from './util'

const { Box, Flex, Text, Button } = require('@chakra-ui/react')

const selctionThreeData = [
  {
    image: sectionthreeone,
  },
  {
    image: sectionthreetwo,
  },
  {
    image: sectionthreethree,
  },
]
const height = '40px'
const width = isMobile ? '180px' : '150px'
const SectionThree = () => {
  const history = useHistory()
  const [autoPlay, setAutoPlay] = useState(false)
  const onSignUp = () => {
    history.push({
      pathname: '/register',
      state: {
        comingFromHome: true,
      },
    }) 
  }
  return (
    <Flex
      flex={'1 0 100%'}
      section={'1'}
      bg="#F6F7FB"
      direction={['column', 'column', 'row']}
      w={'100%'}
      px={[5, 5, 5]}
      py={5}
      justifyContent={'center'}
    >
      <Box
        textAlign={['center', 'left', 'left']}
        w={['90%', '100%', '30%']}
        flex={imageFlex}
      >
        <ImageSlider
          px={[10, 0, 0]}
          sx={[imageSx, imagemd, imagelg]}
          borderRadius={'15px'}
          slides={selctionThreeData}
          autoPlay={autoPlay}
          onClick={() => {
            setAutoPlay(!autoPlay)
          }}
        ></ImageSlider>
      </Box>
      <Box px={[10, 1, 10]} py={[10, 2, 0]} w={textPanelWidth}>
        <Text textAlign={'left'} fontSize={fontMain} fontWeight={900}>
          <Text
            bg="{hyperTextColor}"
            //  color={'#fff'}
            display={'inline'}
            fontWeight={900}
            // px={1}
            fontSize={fontMain}
          >
            {'Don’t just learn the language,'}
          </Text>
        </Text>
        <Text textAlign={'left'} pb={0} fontSize={fontMain} fontWeight={900}>
          {' learn also culture & life.'}
        </Text>
        {!isMobile && (
          <>
            <Text
              textAlign={'left'}
              pt={1}
              fontSize={fontSecond}
              fontWeight={600}
            >
              {
                'Our native teachers go beyond teaching language and bring to you stories, cultural facts, and wisdom associated.'
              }
            </Text>
            <Text
              textAlign={'left'}
              pt={2}
              fontSize={fontSecond}
              fontWeight={600}
            >
              {
                'Our images & videos have been mindfully selected & produced to introduce you the reality of the language you are learning.'
              }
            </Text>
          </>
        )}
        {isMobile && (
          <>
            <Text
              textAlign={'left'}
              pt={3}
              fontSize={fontSecond}
              fontWeight={600}
            >
              {
                'Our native teachers go beyond teaching language and bring to you stories, cultural facts, and wisdom associated. '
              }
            </Text>
            <Text
              textAlign={'left'}
              pt={3}
              fontSize={fontSecond}
              fontWeight={600}
            >
              {
                'Our images & videos have been mindfully selected & produced to introduce you the reality of the language you are learning.'
              }
            </Text>
          </>
        )}
        <Box  pt={[5, 5, 3]} textAlign={['center', 'center', 'inherit']}>
          <Button
            color="#707070"
            w={width}
            h={height}
            //bg={btnBg}
            style={{
              border: '1px solid #707070',
              backgroundColor: '#fff',
            }}
            borderRadius={'5px'}
            _hover={btnHover}
            onClick={onSignUp}
          >
            Sign up Now
          </Button>
        </Box>
      </Box>
    </Flex>
  )
}

export default SectionThree
