import 'src/web.config'
import './index.css'

import { ChakraProvider } from '@chakra-ui/react'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { SWRConfig } from 'swr'

import { AppContainer } from './Container/AppContainer'
import { AuthProvider } from './context/AuthContext'
import { setupStore } from './redux/store'
import theme from './theme'

const store = setupStore({})

ReactDOM.render(
  <SWRConfig value={{ revalidateOnFocus: false, shouldRetryOnError: false }}>
    <Provider store={store}>
      <ChakraProvider theme={theme}>
        <BrowserRouter>
          <AuthProvider>
            <AppContainer />
          </AuthProvider>
        </BrowserRouter>
      </ChakraProvider>
    </Provider>
  </SWRConfig>,
  document.getElementById('root')
)
