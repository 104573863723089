import api from 'src/util/api'
import create from 'zustand'

const useRzpSubscriptionStore = create((set, get) => ({
  subscription: undefined,
  isLoading: false,
  error: undefined,

  actions: {
    fetchSubsciption: async subscriptionId => {
      set(() => ({ isLoading: true }))
      try {
        const subscription = await api.get(
          `get-subscription-rzp?subscriptionId=${subscriptionId}`
        )

        set(() => ({ subscription, isLoading: false }))
      } catch (error) {
        console.log(error)
        set(() => ({ isLoading: false, subscription: null, error }))
      }
    },
  },
}))

export const useRzpSubscription = () =>
  useRzpSubscriptionStore(state => state.subscription)

export const useIsRzpSubscriptionLoading = () =>
  useRzpSubscriptionStore(state => !state.subscription && !state.error)

export const useRzpSubscriptionActions = () =>
  useRzpSubscriptionStore(state => state.actions)
