import API from 'src/util/api'
import Config from 'src/util/config'
import { ADD_ERROR, LOADING_ERROR } from 'src/util/helpers'
import LocalStorageService from 'src/util/localStorageService'

import {
  GET_COURSE_LEFT_MENU,
  HIDE_SPINNER,
  REPORT_ISSUE_FAIL,
  REPORT_ISSUE_SUCCESSFUL,
  SET_BANNERS,
  SET_COURSE_LEFT_MENU,
  SET_COURSES,
  SET_FEEDBACKS,
  SET_LESSON_EXERCISES,
  SET_LESSON_EXERCISES_PROGRESS,
  SET_LESSON_VIDEO,
  SET_LESSON_VOCABULARIES,
  SET_NOTIFICATIONS,
  SET_SELECTED_LANGUAGE,
  SET_SELECTED_LANGUAGE_ID,
  SET_SELECTED_LESSON_LEFT_MENU,
  SET_UNIT_WISE_PROGRESS_COUNT,
  SET_USER_PROGRESS,
  SHOW_SPINNER,
  SIGN_IN_SUCCESSFUL,
  SIGN_OUT_SUCCESSFUL,
  USER_USER_PASSWORD_SUCCESSFUL,
  USER_WATCH_INTRODUCTION_SUCCESSFUL,
} from './actionTypes'
import { setError } from './errorReducer'

const ShowSpinner = () => {
  return { type: SHOW_SPINNER }
}

const HideSpinner = () => {
  return { type: HIDE_SPINNER }
}
const SignInSuccessful = payload => {
  LocalStorageService.setAuthInfo(payload)
  return { type: SIGN_IN_SUCCESSFUL, payload }
}
const SignOutSuccessful = () => {
  LocalStorageService.clearStorage()
  return { type: SIGN_OUT_SUCCESSFUL }
}
const ReportIssueSuccessful = () => {
  return { type: REPORT_ISSUE_SUCCESSFUL }
}
const UserWatchIntroductionSuccessful = () => {
  return { type: USER_WATCH_INTRODUCTION_SUCCESSFUL }
}
const UserUserPasswordSuccessful = () => {
  return { type: USER_USER_PASSWORD_SUCCESSFUL }
}
const ReportIssueFail = () => {
  return { type: REPORT_ISSUE_FAIL }
}

const SetCourses = payload => {
  return { type: SET_COURSES, payload }
}

const SetBanners = payload => {
  return { type: SET_BANNERS, payload }
}
const SetUnitWiseProgressCount = payload => {
  return { type: SET_UNIT_WISE_PROGRESS_COUNT, payload }
}

const SetUserProgress = payload => {
  return { type: SET_USER_PROGRESS, payload }
}

export const SetCourseLeftMenu = payload => {
  return { type: SET_COURSE_LEFT_MENU, payload }
}

const SetFeedbacks = payload => {
  return { type: SET_FEEDBACKS, payload }
}

const SetLessonExercisesProgress = payload => {
  return { type: SET_LESSON_EXERCISES_PROGRESS, payload }
}
export const SetLessonExercises = payload => {
  return { type: SET_LESSON_EXERCISES, payload }
}
export const SetLessonVideo = payload => {
  return { type: SET_LESSON_VIDEO, payload }
}

const SetNotifications = payload => {
  return { type: SET_NOTIFICATIONS, payload }
}
export const SetLessonVocabularies = payload => {
  return { type: SET_LESSON_VOCABULARIES, payload }
}

export const setSelectedLesson = payload => ({
  type: SET_SELECTED_LESSON_LEFT_MENU,
  payload,
})

export const setSelectedLanguageId = payload => {
  payload && localStorage.setItem('selectedLanguageId', payload)
  return {
    type: SET_SELECTED_LANGUAGE_ID,
    payload,
  }
}

export const setSelectedLanguage = payload => {
  payload && localStorage.setItem('selectedLanguage', JSON.stringify(payload))
  return {
    type: SET_SELECTED_LANGUAGE,
    payload,
  }
}

export const SignUp = params => {
  return dispatch => {
    dispatch(ShowSpinner())

    API.post(Config.API_URI + 'signUp', params)
      .then(data => dispatch(SignInSuccessful(data)))
      .catch(error => dispatch(setError(error)))
      .finally(() => dispatch(HideSpinner()))
  }
}

export const getCourses = () => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(ShowSpinner())
    API.get(Config.API_URI + 'userCourses')
      .then(data => {
        dispatch(SetCourses(data))
        return resolve(data)
      })
      .catch(error => dispatch(setError(LOADING_ERROR('userCourses'))))
      .finally(() => dispatch(HideSpinner()))
  })
}
export const getBanners = () => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(ShowSpinner())
    API.get(Config.API_URI + 'banners')
      .then(data => {
        const filteredData = data.docs.filter(
          item => item.isActive && item.isPublished
        )
        dispatch(SetBanners(filteredData))
        return resolve(filteredData)
      })
      .catch(error => dispatch(setError(LOADING_ERROR('getBanners'))))
      .finally(() => dispatch(HideSpinner()))
  })
}
export const GetUnitWiseProgressCount = courseId => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(ShowSpinner())
    SetUnitWiseProgressCount([])

    API.get(Config.API_URI + 'unitWiseProgressCount/' + courseId)
      .then(data => {
        dispatch(SetUnitWiseProgressCount(data))
        return resolve(data)
      })
      .catch(error =>
        dispatch(setError(LOADING_ERROR('learning progress by unit')))
      )
      .finally(() => dispatch(HideSpinner()))
  })
}

export const GetLessonExerciseProgress = (
  lessonId,
  chapterId,
  unitId,
  courseId
) => {
  return dispatch => {
    dispatch(ShowSpinner())
    const url = `${Config.API_URI}lessonExercisesProgress/${lessonId}`
    API.get(url, {
      params: {
        chapterId,
        unitId,
        courseId,
      },
    })
      .then(response => {
        dispatch(SetLessonExercisesProgress(response))
      })
      .catch(error => dispatch(setError(LOADING_ERROR('user progress'))))
      .finally(() => dispatch(HideSpinner()))
  }
}

export const UpdateLastVisitedPage = lastVisitedPage => {
  return dispatch => {
    API.post(`${Config.API_URI}lastVisitedPage`, { lastVisitedPage }).catch(
      () => dispatch(setError(LOADING_ERROR('update last visited page')))
    )
  }
}

export const ResetLessonExercises = lessonId => {
  return dispatch => {
    dispatch(ShowSpinner())

    API.patch(`${Config.API_URI}resetLessonExercisesProgress/${lessonId}`)
      .then(data => {
        dispatch(SetLessonExercisesProgress(data))
      })
      .catch(() => dispatch(setError(LOADING_ERROR('reset lesson exercises'))))
      .finally(() => dispatch(HideSpinner()))
  }
}

export const UpdateUserProgress = params => {
  return async dispatch => {
    try {
      dispatch(ShowSpinner())
      const data = await API.post('updateuserprogress', params)
      dispatch(SetLessonExercisesProgress(data))
      dispatch(SetUserProgress(data))
      dispatch(HideSpinner())
      return data
    } catch {
      dispatch(setError(ADD_ERROR('update user progress')))
    } finally {
      dispatch(HideSpinner())
    }
  }
}

export const addFeedback = params => async dispatch => {
  try {
    dispatch(ShowSpinner())
    const data = await API.post('addfeedback', params)
    dispatch(HideSpinner())
    return data
  } catch {
    dispatch(setError(ADD_ERROR('Add feedback')))
  } finally {
    dispatch(HideSpinner())
  }
}

export const userSignIn = data => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(ShowSpinner())
    API.post(Config.API_URI + 'signIn', data)
      .then(data => {
        dispatch(SignInSuccessful(data))
        return resolve(data)
      })
      .catch(error => {
        dispatch(setError(error))
        reject(error)
      })
      .finally(() => dispatch(HideSpinner()))
  })
}

export const impersonateUser = data => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(ShowSpinner())
    API.post(Config.API_URI + 'impersonateUser', data)
      .then(data => {
        dispatch(SignInSuccessful(data))
        return resolve(data)
      })
      .catch(error => {
        dispatch(setError(error))
        return resolve({ error: error, status: 400 })
      })
      .finally(() => dispatch(HideSpinner()))
  })
}
export const userSignOut = user => {
  return dispatch => {
    dispatch(SignOutSuccessful())
  }
}

export const GetCourseLeftMenu = unitId => {
  return dispatch => {
    dispatch(ShowSpinner())
    dispatch({ type: GET_COURSE_LEFT_MENU })

    API.get(Config.API_URI + 'courseLeftMenu/' + unitId)
      .then(data => dispatch(SetCourseLeftMenu(data)))
      .catch(() => dispatch(setError(LOADING_ERROR('course left menu'))))
      .finally(() => dispatch(HideSpinner()))
  }
}

export const GetUsersFeedbacks = courseId => {
  return dispatch => {
    dispatch(ShowSpinner())

    API.get(Config.API_URI + 'feedbacks/' + courseId)
      .then(data => dispatch(SetFeedbacks(data)))
      .catch(() => dispatch(setError(LOADING_ERROR('User feedback'))))
      .finally(() => dispatch(HideSpinner()))
  }
}

export const GetLessonExercises = lessonId => {
  return dispatch => {
    dispatch(ShowSpinner())
    API.get(`lessonExercises/${lessonId}`)
      .then(data => {
        dispatch(SetLessonExercises(data))
      })
      .catch(error => {
        console.log(error)
        dispatch(setError(LOADING_ERROR('lesson exercises')))
      })
      .finally(() => dispatch(HideSpinner()))
  }
}

export const GetLessonVocabularies = lessonId => {
  return dispatch => {
    dispatch(ShowSpinner())
    API.get(`${Config.API_URI}lessonVocabularies/${lessonId}`)
      .then(data => {
        dispatch(SetLessonVocabularies(data))
      })
      .catch(error => dispatch(setError(LOADING_ERROR('lesson vocabularies'))))
      .finally(() => dispatch(HideSpinner()))
  }
}

export const GetLessonVideo = videoId => {
  return dispatch => {
    dispatch(ShowSpinner())

    API.get(Config.API_URI + 'videoDetails/' + videoId)
      .then(data => dispatch(SetLessonVideo(data)))
      .catch(() => dispatch(setError(LOADING_ERROR('lesson video'))))
      .finally(() => dispatch(HideSpinner()))
  }
}
export const GetNotifications = () => {
  return dispatch => {
    API.get(Config.API_URI + 'userNotification')
      .then(data => dispatch(SetNotifications(data?.results)))
      .catch(error => dispatch(setError(LOADING_ERROR('Notification'))))
      .finally(() => dispatch(HideSpinner()))
  }
}
export const postquestion = data => {
  return dispatch => {
    dispatch(ShowSpinner())
    API.post(Config.API_URI + 'postquestion/', data)
      .then(() => dispatch(ReportIssueSuccessful()))
      .catch(() => {
        dispatch(setError(LOADING_ERROR('lesson video')))
        dispatch(ReportIssueFail())
      })
      .finally(() => dispatch(HideSpinner()))
  }
}
export const UpdateUserNotification = data => {
  return dispatch => {
    dispatch(ShowSpinner())
    API.post(Config.API_URI + 'updateUserNotification/', data)
      .then(() => dispatch(GetNotifications()))
      .catch(() => {
        dispatch(setError(LOADING_ERROR('updateUserNotification')))
      })
      .finally(() => dispatch(HideSpinner()))
  }
}
export const postReport = data => {
  return dispatch => {
    dispatch(ShowSpinner())
    API.post(Config.API_URI + 'report/', data)
      .then(() => dispatch(ReportIssueSuccessful()))
      .catch(() => {
        dispatch(setError(LOADING_ERROR('Unable to Submit issue')))
        dispatch(ReportIssueFail())
      })
      .finally(() => dispatch(HideSpinner()))
  }
}
export const userWatchIntroduction = data => {
  return dispatch => {
    dispatch(ShowSpinner())
    API.post(Config.API_URI + 'userWatchIntroduction/', data)
      .then(() => dispatch(UserWatchIntroductionSuccessful()))
      .catch(() => {
        dispatch(setError(LOADING_ERROR('Unable to Submit issue')))
        dispatch(ReportIssueFail())
      })
      .finally(() => dispatch(HideSpinner()))
  }
}
export const updateUserPassword = data => {
  return dispatch => {
    dispatch(ShowSpinner())
    API.post(Config.API_URI + 'updateUserPassword', data)
      .then(() => dispatch(UserUserPasswordSuccessful()))
      .catch(() => {
        dispatch(
          setError(LOADING_ERROR('Unable to Update User Password issue'))
        )
        dispatch(ReportIssueFail())
      })
      .finally(() => dispatch(HideSpinner()))
  }
}
