import {
  Box,
  Center,
  CloseButton,
  Flex,
  HStack,
  Stack,
  useToast,
} from '@chakra-ui/react'
import groupBy from 'lodash/groupBy'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import DashboardBanner from 'src/component/DashboardBanner'
import SideList from 'src/component/SideList'
import { useUser } from 'src/hooks/useUser'
import { getBanners, getCourses, setSelectedLesson } from 'src/redux/actions'
import { useLoginStore } from 'src/redux/useLoginStore'
import API from 'src/util/api'
import Config from 'src/util/config'

import Carousel from '../component/Carousel'

const difficultyLevelMapper = {
  continueLearning: 'Continue Learning',
  absoluteBeginners: 'Absolute Beginners Level',
  beginner: 'Beginner Level',
  intermidiate: 'Intermediate Level',
  advanced: 'Advanced Level',
  fluent: 'Fluent Level',
  interestingStuff: 'Interesting Stuff',
  allLevels: 'All Levels',
}

const difficultyLevelOrder = {
  continueLearning: '1',
  absoluteBeginners: '2',
  beginner: '3',
  intermidiate: '4',
  advanced: '5',
  fluent: '6',
  all: '7',
}
let renderNewCourseKey = []

export default function MyCourses() {
  const dispatch = useDispatch()
  const [disableLeftArrow] = useState(false)
  const [disableRightArrow] = useState(true)
  const [showAllCourse, setShowAllCourse] = useState(true)
  const loading = useSelector(store => store.mainReducer.loading)
  const toast = useToast()
  const courseDetails = useSelector(
    store => store.mainReducer.unitWiseProgressCount
  )
  const { user: auth, isAdmin } = useUser()

  const [activeItem, setActiveItem] = useState('levels')

  const onGetActivationLink = async () => {
    toast.closeAll()
    const response = await API.post(Config.API_URI + 'getActivationLink', {
      email: auth.email,
      url: window.location.origin,
    })
    if (response) {
      toast({
        status: 'success',
        title: 'You have successfully sent activation link your account',
        position: 'bottom-left',
        isClosable: true,
        containerStyle: {
          fontSize: '14px',
        },
      })
    } else {
      toast({
        status: 'warning',
        title:
          'we are unable to get your email address, please try after sometime.',
        position: 'bottom-left',
        isClosable: true,
        containerStyle: {
          fontSize: '14px',
        },
      })
    }
  }

  const showActivationWindow = () => {
    if (auth?.isNotValidated) {
      toast({
        duration: 99000,
        render: () => (
          <HStack color="white" p={2} bg="#2469F5">
            <Box>
              Please{' '}
              <a
                onClick={onGetActivationLink}
                href="#"
                style={{ pointer: 'cursor', textDecoration: 'underline' }}
              >
                Click here
              </a>{' '}
              to get activation link on your email
            </Box>
            <CloseButton
              onClick={() => {
                toast.closeAll()
              }}
              size={'sm'}
            ></CloseButton>
          </HStack>
        ),
        position: 'top-right',
        isClosable: true,
        containerStyle: {
          fontSize: '14px',
          marginTop: '70px',
        },
      })
    }
  }
  useEffect(() => {
    dispatch(getBanners())
    dispatch(setSelectedLesson())
    showActivationWindow()
  }, [])

  if (loading) {
    return null
  }

  if (!courseDetails && !loading) {
    return <Center>No data found</Center>
  }

  const groupedCourse = groupBy(
    courseDetails,
    course => course?.difficultyLevel || 'interestingStuff'
  )

  const courseKeys = Object.keys(groupedCourse).sort((a, b) => {
    return difficultyLevelOrder[a] - difficultyLevelOrder[b]
  })

  const onSeeAllBtnClick = (key, showAllCourse) => {
    renderNewCourseKey = [key]
    setShowAllCourse(showAllCourse)
  }

  return (
    <Stack px="10px">
      <DashboardBanner></DashboardBanner>
      <Flex direction="row">
        <SideList activeItem={activeItem} setActiveItem={setActiveItem} />

        <Stack position="relative" overflow="hidden" marginTop={'10px'}>
          {!showAllCourse &&
            renderNewCourseKey.map(key => (
              <Carousel
                navUrl="coursedetails"
                key={key}
                auth={auth}
                id={key}
                courseKey={key}
                showAllCourse={showAllCourse}
                seeAllBtnFn={onSeeAllBtnClick}
                contentEditable={true}
                isAdmin={isAdmin}
                title={difficultyLevelMapper[key]}
                data={groupedCourse[key]}
                disableLeftArrow={disableLeftArrow}
                disableRightArrow={disableRightArrow}
              />
            ))}
          {showAllCourse &&
            courseKeys.map(key => (
              <Carousel
                navUrl="coursedetails"
                key={key}
                auth={auth}
                id={key}
                courseKey={key}
                showAllCourse={showAllCourse}
                seeAllBtnFn={onSeeAllBtnClick}
                contentEditable={true}
                isAdmin={isAdmin}
                title={difficultyLevelMapper[key]}
                data={groupedCourse[key]}
                disableLeftArrow={disableLeftArrow}
                disableRightArrow={disableRightArrow}
              />
            ))}
        </Stack>
      </Flex>
    </Stack>
  )
}
