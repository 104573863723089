export const SIGN_IN_SUCCESSFUL = 'SIGN_IN_SUCCESSFUL'
export const SIGN_OUT_SUCCESSFUL = 'SIGN_OUT_SUCCESSFUL'
export const USER_LOGGED_OUT = 'USER_LOGGED_OUT'

export const SET_COURSES = 'SET_COURSES'
export const SET_USER_PROGRESS = 'SET_USER_PROGRESS'
export const SET_UNIT_WISE_PROGRESS_COUNT = 'SET_UNIT_WISE_PROGRESS_COUNT'
export const SET_EXERCISE_DETAILS = 'SET_EXERCISE_DETAILS'
export const SHOW_SPINNER = 'SHOW_SPINNER'
export const HIDE_SPINNER = 'HIDE_SPINNER'
export const GET_COURSE_LEFT_MENU = 'GET_COURSE_LEFT_MENU'
export const SET_COURSE_LEFT_MENU = 'SET_COURSE_LEFT_MENU'
export const SET_LESSON_EXERCISES_PROGRESS = 'SET_LESSON_EXERCISES_PROGRESS'
export const SET_LESSON_EXERCISES = 'SET_LESSON_EXERCISES'
export const SET_LESSON_VIDEO = 'SET_LESSON_VIDEO'
export const SET_LESSON_VOCABULARIES = 'SET_LESSON_VOCABULARIES'
export const CLEAN_LESSON_VOCABULARIES = 'CLEAN_LESSON_VOCABULARIES'
export const REPORT_ISSUE_SUCCESSFUL = 'REPORT_ISSUE_SUCCESSFUL'
export const REPORT_ISSUE_FAIL = 'REPORT_ISSUE_FAIL'
export const SET_SELECTED_LESSON_LEFT_MENU = 'SET_SELECTED_LESSON_LEFT_MENU'
export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS'
export const SET_FEEDBACKS = 'SET_FEEDBACKS'
export const USER_WATCH_INTRODUCTION_SUCCESSFUL =
  'USER_WATCH_INTRODUCTION_SUCCESSFUL'
export const USER_USER_PASSWORD_SUCCESSFUL = 'USER_USER_PASSWORD_SUCCESSFUL'
export const SET_SELECTED_LANGUAGE_ID = 'SET_SELECTED_LANGUAGE_ID'
export const SET_SELECTED_LANGUAGE = 'SET_SELECTED_LANGUAGE'
export const SET_BANNERS = 'SET_BANNERS'
