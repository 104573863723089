import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react'
import React, { useState } from 'react'
import useSubscription from 'src/hooks/useSubscription'
import { useUser } from 'src/hooks/useUser'
import API from 'src/util/api'
import useSWR from 'swr'

const fetcher = async url => {
  const data = await API.get(url)
  return data?.results?.items || []
}

export default function PricingRzp() {
  const [isCreatingCheckoutSession, setIsCreatingCheckoutSession] =
    useState(false)
  const [isPaymentError, setIsPaymentError] = useState(false)
  const [isPaymentSuccess, setIsPaymentSuccess] = useState(false)

  const { data, error } = useSWR('rzp-plans', fetcher, {})
  const { user } = useUser()
  const { isSubscriptionActive, isOldSubscription, manageSubscrption } =
    useSubscription({ enableRazorPay: true })

  if (error) {
    return <Text>Can not load plans</Text>
  }

  if (!data && !error) return <Text>Loading...</Text>

  function getPlanName(plan) {
    if (plan.item) {
      return `${plan.item.name}`
    }
    return 'N/A'
  }

  function getFormattedPrice(plan) {
    const amount = plan.item.amount / 100

    const userFriendlyAmount = new Intl.NumberFormat(
      navigator.language || 'en-US',
      {
        style: 'currency',
        currency: 'INR',
      }
    ).format(amount)

    return userFriendlyAmount
  }

  if (isSubscriptionActive) {
    return (
      <Stack spacing="4" width="full" alignItems={'center'} mt="20">
        <Text fontWeight={'bold'} fontSize="2xl">
          You have active sbscription
        </Text>
        {!isOldSubscription && (
          <Button variant="primary" width="unset" onClick={manageSubscrption}>
            Manage Subscription
          </Button>
        )}
      </Stack>
    )
  }

  async function updateUserWithSubscription(subscriptionId) {
    await API.post('update-user-rzp', {
      subscriptionId,
      email: user.email,
    })
    setIsPaymentSuccess(true)
  }

  async function createRzpSubscription(planId) {
    setIsCreatingCheckoutSession(true)
    try {
      const res = await API.post('create-rzp-subscription', { planId })
      const options = {
        key: process.env.REACT_APP_RZP_KEY,
        subscription_id: res.id,
        name: 'Cocoma Digital',
        handler: function (response) {
          console.log(response)
          updateUserWithSubscription(response.razorpay_subscription_id)
          // verfiy razorpay signature
        },
        prefill: {
          name: user.firstName + ' ' + user.lastName,
          email: user.email,
          contact: user.phone,
        },
      }

      // Save customer id (res.customerId) in table
      const rzp = new window.Razorpay(options)
      rzp.on('payment.failed', function (response) {
        console.log('payment failed', response)
        setIsPaymentError(true)
      })

      rzp.open()
    } catch (err) {
      console.log(err)
    }
    setIsCreatingCheckoutSession(false)
  }

  const visiblePlans = [
    'plan_KrTbarDuo3wehR',
    'plan_KrTa66bQM3NcRr',
    'plan_KrTaagqoSDKtfA',
    'plan_KrTb122yhfLZQP',
  ].map(planId => data.find(plan => plan.id === planId))

  return (
    <Stack justifyContent="center">
      <Flex
        direction={['column', 'column', 'row']}
        backgroundColor="#F4F4FF"
        justifyContent="center"
        paddingY="5"
        gap={2}
        mt="20"
      >
        {visiblePlans.map(plan => {
          return (
            <Box
              key={plan.id}
              bgColor="white"
              boxShadow="0px 3px 6px #00000029"
              padding="15px"
            >
              <VStack>
                <Text fontSize="28px" fontWeight="semibold">
                  {getPlanName(plan)}
                </Text>
                <Text fontSize="28px" fontWeight="semibold" color="#2469F5">
                  {getFormattedPrice(plan)}
                </Text>
                <Text fontSize="14px" fontWeight="semibold">
                  Full access of complete library
                </Text>
                <Stack
                  fontSize="12px"
                  fontWeight="medium"
                  mt="8px"
                  spacing="4px"
                  width={'full'}
                >
                  <Text>27+ course</Text>
                  <Text>1000+ video lessons</Text>
                  <Text>3,000+ Exercises</Text>
                  <Text>Access to all features</Text>
                  <Text>Weekly new content updates</Text>
                </Stack>
                <Button
                  variant="outline"
                  mx="auto"
                  onClick={() => createRzpSubscription(plan.id)}
                  // onClick={() => createSubscrption(plan.id)}
                  isLoading={isCreatingCheckoutSession}
                >
                  Get Started
                </Button>
              </VStack>
            </Box>
          )
        })}
      </Flex>
      {isPaymentError && (
        <PaymentFailedModal onClose={() => setIsPaymentError(false)} />
      )}
      {isPaymentSuccess && (
        <PaymentSuccessModal
          onClose={() => {
            setIsPaymentSuccess(false)
            window.location.reload()
          }}
        />
      )}
    </Stack>
  )
}

function PaymentSuccessModal({ onClose }) {
  return (
    <Modal isOpen={true} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Payment Success</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text>Congratulations! Now you have access to paid content</Text>
        </ModalBody>

        <ModalFooter>
          <Button variant="primary" onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

function PaymentFailedModal({ onClose }) {
  return (
    <>
      <Modal isOpen onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Payment Failed</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Some error occured during payment. Please try again
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
