import { Flex, Text } from '@chakra-ui/react'

export const HorizontalSeparator = ({ containerStyle }) => (
  <Flex {...containerStyle}>
    <Flex flex={1} alignItems="center">
      <hr style={{ width: '100%', borderWidth: 1 }} />
    </Flex>
    <Text margin="0 8px" fontWeight="bold">
      OR
    </Text>
    <Flex flex={1} alignItems="center">
      <hr style={{ width: '100%', borderWidth: 1 }} />
    </Flex>
  </Flex>
)
