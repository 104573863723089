import { sortBy } from 'lodash'

export const ExcerciseTypes = {
  LISTEN_WITH_IMAGE: 'LISTEN WITH IMAGE',
  LISTEN_WITH_AUDIO: 'LISTEN WITH AUDIO',
  LISTEN_WITH_TEXT: 'LISTEN WITH TEXT',
  LISTEN_WITH_ORDER: 'LISTEN WITH ORDER',
  LISTEN_WITH_TYPE: 'LISTEN WITH TYPE',
  LISTEN_WITH_RECORD: 'LISTEN WITH RECORD',
  WATCH_WITH_AUDIO: 'WATCH WITH AUDIO',
  WATCH_WITH_TEXT: 'WATCH WITH TEXT',
  WATCH_WITH_ORDER: 'WATCH WITH ORDER',
  WATCH_WITH_TYPE: 'WATCH WITH TYPE',
  WATCH_WITH_RECORD: 'WATCH WITH RECORD',
  WATCH_VIDEO: 'WATCH VIDEO',
  WATCH_4LVIDEO: 'WATCH 4LVIDEO',
}

export const LOADING_ERROR = asset =>
  `Error during loading ${asset}, Please try again.`
export const ADD_ERROR = asset =>
  `Error during adding/updating ${asset}, Please try again.`
export const DELETE_ERROR = asset =>
  `Error during deleting ${asset}, Please try again.`

export const filterByKeyValue = (list = [], key, value) => {
  if (list && key && value) {
    return list.filter(item => item[key]?.id === value)
  }
  return list
}

export const extractMediaOptions = options => {
  let optionList = []

  if (options) {
    for (let item of Object.entries(options)) {
      if (item[0].includes('option') && item[0].includes('File')) {
        optionList.push({ key: item[0], value: item[1] })
      }
    }
  }
  return optionList
}

export const extractOptions = options => {
  if (options) {
    let onlyOptions = Object.keys(options).filter(
      optionKey =>
        optionKey.includes('option') &&
        !optionKey.includes('File') &&
        !optionKey.includes('optiontype')
    )

    if (onlyOptions.length === 0) {
      onlyOptions = Object.keys(options).filter(
        optionKey =>
          optionKey.includes('option') &&
          optionKey.includes('File') &&
          !optionKey.includes('optiontype')
      )
    }

    return onlyOptions.reduce((result, optionKey) => {
      const nativeOptionKey = `native${
        optionKey[0].toUpperCase() + optionKey.substring(1)
      }`
      const optionFileKey = `${optionKey}File`
      const nativeValue = options[nativeOptionKey]
      const nativeFile = options[optionFileKey]

      return [
        ...result,
        {
          key: optionKey,
          value: options[optionKey],
          nativeValue,
          nativeFile,
        },
      ]
    }, [])
  }
  return []
}

export const checkAnswer = (selectedOption, selectedExercise) => {
  if (selectedExercise.questionType === ExcerciseTypes.LISTEN_WITH_TYPE) {
    const validAnswers = selectedExercise.options.answer
      .split(',')
      .map(val => val.trim())

    return validAnswers.includes(selectedOption)
  }
  return typeof selectedOption === 'object'
    ? selectedOption?.key?.includes(selectedExercise.options.answer.trim())
    : selectedExercise.options.hasOwnProperty('option1')
    ? selectedExercise.options.answer?.trim() === selectedOption
    : selectedExercise.options.answer?.includes(selectedOption)
    ? true
    : false
}

const customSort = data => sortedByOrder(sortBy(data, 'name'))

export const sortedByOrder = data =>
  data.sort((firstEl, secondEl) => {
    if (firstEl.sortOrder === 0) return 1 //Return 1 so that b goes first
    if (secondEl.sortOrder === 0) return -1
    return firstEl?.sortOrder - secondEl?.sortOrder
  })

export const sortChapterAndLessons = chapters =>
  customSort(chapters).map(chapter => {
    const sortedLessons = chapter.lessons.length
      ? customSort(chapter.lessons)
      : []

    return {
      ...chapter,
      lessons: sortedLessons,
    }
  })

const EMAIL_REGEX = /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i

export const isValidEmail = email => {
  return email && EMAIL_REGEX.test(email)
}

export const getCourseCreatorName = auth => {
  return 'Anil Mahato'
}

export const carouselRightBtnClick = e => {
  let carousel = e.currentTarget.parentElement
  const slides = carousel.querySelector('div')
  let scrollWidth = parseInt(getComputedStyle(slides).width, 10)
  carousel.scrollTo({
    top: 0,
    left: carousel.scrollLeft - scrollWidth,
    behavior: 'smooth',
  })
}
export const carouselLeftBtnClick = e => {
  let carousel = e.currentTarget.parentElement
  const slides = carousel.querySelector('div')
  let scrollWidth = parseInt(getComputedStyle(slides).width, 10)
  carousel.scrollTo({
    top: 0,
    left: carousel.scrollLeft + scrollWidth,
    behavior: 'smooth',
  })
}
