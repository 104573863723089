import {
  Center,
  HStack,
  List,
  ListItem,
  Stack,
  Switch,
  Text,
} from '@chakra-ui/react'
import _ from 'lodash'
import React, { Fragment, useState } from 'react'
import { useFeatureFlagAction, useFlagList } from 'src/redux/useFeatureFlags'
import api from 'src/util/api'

async function updateFlagInformation(flag, enabled, siteLevel = false) {
  const flagObj = _.pick(flag, ['name', 'enabled', 'siteLevel'])

  // update flag information using api
  if (flag._id) {
    return api.put(`/flag/${flag._id}`, { ...flagObj, enabled, siteLevel })
  }
  // If flag is not in database, create it
  return api.post('/flag', { ...flagObj, enabled, siteLevel })
}

export default function FeatureFlags() {
  const flagList = useFlagList()
  const { getFlags } = useFeatureFlagAction()
  const [showSiteLevelFeatures, setShowSiteLevelFeatures] = useState(false)

  const onToggle = async (e, flag) => {
    // update flag information using api
    try {
      await updateFlagInformation(flag, e.target.checked, showSiteLevelFeatures)
      getFlags()
    } catch (e) {
      // TODO: show error message to user
      console.log(e)
    }
  }

  return (
    <Fragment>
      <Center mt="4">
        <Stack>
          <HStack mx="auto">
            <Text>User</Text>
            <Switch
              onChange={e => setShowSiteLevelFeatures(e.target.checked)}
            />
            <Text>Site</Text>
          </HStack>
          <List spacing={3}>
            {flagList.map(flag => (
              <ListItem key={flag.name}>
                <Text minW={400} display="inline-block">
                  {flag.name}
                </Text>
                <Switch
                  onChange={e => onToggle(e, flag)}
                  isChecked={
                    !showSiteLevelFeatures ? flag.enabled : flag.siteLevel
                  }
                />
              </ListItem>
            ))}
          </List>
        </Stack>
      </Center>
    </Fragment>
  )
}
