import React from 'react'

const NoRecordFound = ({ textToDisplay }) => {
  return (
    <div className="voc-container">
      <div className="col-md-12">
        <div
          style={{
            padding: '20px',
            background: '#f3f3f3',
            boxShadow: '0px 0px 4px 1px #3c3c3c30',
          }}
        >
          <p
            style={{
              textAlign: 'center',
            }}
          >
            {' '}
            This lesson does not have any {textToDisplay}
          </p>
        </div>
      </div>
    </div>
  )
}

export default React.memo(NoRecordFound)
