import { HStack, Image, ListItem, Stack, Text, Box } from '@chakra-ui/react'
import ReactStars from 'react-rating-stars-component'
import profile from '../asset/images/profile2x.png'

const UserFeedbacks = ({ userFeedback }) => {
  return (
    <>
      <Stack>
        <HStack>
          <Image boxSize="20px" src={profile} />{' '}
          <Text fontWeight={'600'} size="sm">
            {userFeedback?.user.name}
          </Text>
        </HStack>
        <Box style={{ marginLeft: '20px', marginTop:"-5px" }}>
          <ReactStars
            count={5}
            value={parseFloat(userFeedback?.rating) || 5}
            size={24}
            isHalf={true}
            edit={false}
            color="lightGray"
            emptyIcon={<i className="far fa-star"></i>}
            halfIcon={<i className="fa fa-star-half-alt"></i>}
            fullIcon={<i className="fa fa-star"></i>}
            activeColor="#ffd700"
          />
          <Text>{userFeedback?.comment}</Text>
        </Box>
      </Stack>
    </>
  )
}

export default UserFeedbacks
