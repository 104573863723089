import { VStack } from '@chakra-ui/react'
import { useState } from 'react'

import ListenAudio from '../HelperComponents/ListenAudio'
import TagOption from './TagOption'
import useMediaOptions from './useMediaOptions'

const ListenWithText = ({
  exercise,
  setSelectedAnswer,
  disabled,
  ...props
}) => {
  const [selectedOption, setSelectedOption] = useState({})
  const { mediaList } = useMediaOptions({ exercise })

  const onAnswerClick = option => {
    setSelectedOption(option)
    setSelectedAnswer(option)
  }

  return (
    <VStack
      justifyContent="center"
      alignItems="center"
      gap={[2, 2, 5]}
      data-testid={props['data-testid']}
    >
      {(exercise.audio || exercise.slowAudio) && (
        <ListenAudio data={exercise} />
      )}
      <VStack textAlign={'center'}>
        {mediaList.map(
          (option, i) =>
            option?.value && (
              <TagOption
                option={option}
                disabled={disabled}
                onClick={() => !disabled && onAnswerClick(option)}
                width={['350px']}
                boxShadow={option === selectedOption ? '5px 5px #12357B' : ''}
                border={'1px solid #707070'}
                key={option.key + i}
              />
            )
        )}
      </VStack>
    </VStack>
  )
}

export default ListenWithText
