const HLine = () => (
  <div
    style={{
      width: '100%',
      borderWidth: 0.1,
      marginTop: 2,
      marginBottom: 2,
      boxShadow: 'inset 0px 0px 0px 10px #a0a0a0',
      MozBoxShadow: 'inset 0px 0px 0px 10px #a0a0a0',
    }}
  />
)

export default HLine
