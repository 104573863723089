import './carousel.css'

import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons'
import { Divider, HStack, Stack, Text } from '@chakra-ui/react'
import React from 'react'
import { carouselLeftBtnClick, carouselRightBtnClick } from 'src/util/helpers'

import { getFileUrl } from './CourseDetail/Practice/Util'
import ImageCard from './ImageCard'

export default function Carousel({
  title,
  data,
  auth,
  dataKeyName = 'name',
  navUrl = 'coursedetails',
  hasStartButton = true,
  contentEditable = false,
  isAdmin,
  courseKey,
  showProgress,
  seeAllBtnFn,
  showAllCourse,
}) {
  return (
    <Stack gap="1">
      <HStack>
        {title && (
          <Text color="#2469F5" fontSize={18} fontWeight={'bolder'}>
            {title}
          </Text>
        )}
        {seeAllBtnFn && (
          <button
            style={{ fontSize: '12px', marginLeft: '20px' }}
            onClick={() => {
              seeAllBtnFn(courseKey, !showAllCourse)
            }}
          >
            {!showAllCourse && <ChevronLeftIcon w={4} h={4} />}
            {showAllCourse ? 'See All' : 'Back to learn'}
            {showAllCourse && <ChevronRightIcon w={4} h={4} />}
          </button>
        )}
      </HStack>
      <Divider />
      <HStack
        className={showAllCourse ? 'carouselSlider' : 'carouselSliderForAll'}
        overflow="scroll"
      >
        {/* <CarouselComponent> */}
        {showAllCourse && data.length > 2 && (
          <button
            className="carousel-left-arrow arrow"
            onClick={carouselRightBtnClick}
            // disabled={disableLeftArrow}
          >
            &lt;
          </button>
        )}
        {data.map((crs, index) => {
          let progress = 0
          if (crs.progress && crs.progress.progress) {
            progress = crs.progress.progress
          }
          let continueLearning = false
          if (crs.difficultyLevel === 'continueLearning') {
            showProgress = true
            continueLearning = true
            navUrl = `introduction/course/${crs.course}/chapter/${crs.chapter}/unit/${crs.unit}/lesson/${crs.lesson}`
          }
          return (
            <ImageCard
              navUrl={navUrl}
              hasStartButton={hasStartButton}
              key={crs.id + `${index}`}
              contentEditable={contentEditable}
              isAdmin={isAdmin}
              auth={auth}
              showAllCourse={showAllCourse}
              id={crs.id || crs._id}
              lessonCount={crs.lessonCount}
              activitiesCount={crs.activitiesCount}
              chapterCount={crs.chapterCount}
              linkedExerciseCount={crs?.linkedExerciseIds?.length}
              linkedVocabularyCount={crs?.linkedVocabularyIds?.length}
              src={
                crs?.videoThumbnail
                  ? getFileUrl(crs.videoThumbnail)
                  : 'https://bit.ly/2Z4KKcF'
              }
              continueLearning={continueLearning}
              name={crs[dataKeyName]}
              hrs={crs['hrs']}
              minutes={crs['minutes']}
              seconds={crs['seconds']}
              progress={progress}
              showProgress={showProgress}
            />
          )
        })}
        {showAllCourse && data.length > 2 && (
          <button
            className="carousel-right-arrow arrow"
            onClick={carouselLeftBtnClick}
            // disabled={disableRightArrow}
          >
            &gt;
          </button>
        )}
        {/* </CarouselComponent> */}
      </HStack>
    </Stack>
  )
}
