import { HStack } from '@chakra-ui/react'

import RightAnswer from '../HelperComponents/RightAnswer'
import WatchVideo from '../HelperComponents/WatchVideo'

const WatchWithType = ({ exercise, ...props }) => {
  return (
    <HStack alignItems={'flex-start'}>
      <WatchVideo exercise={exercise} />
      <RightAnswer {...props} />
    </HStack>
  )
}

export default WatchWithType
