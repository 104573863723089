/* eslint-disable react-hooks/exhaustive-deps */
import { Flex, HStack } from '@chakra-ui/react'

import ListenAudio from '../HelperComponents/ListenAudio'
import HLine from './HLine'
import TagOption, { EmptyTag } from './TagOption'
import useWithOrder from './useWithOrder'

const ListenWithOrder = ({
  exercise,
  setSelectedAnswer,
  disabled,
  selectedAnswer,
}) => {
  const { mediaList, selectedOptions, onItemClick, onSelectedItemClick } =
    useWithOrder({
      exercise,
      setSelectedAnswer,
      selectedAnswer,
      hasAudio: true,
    })

  return (
    <Flex padding={[0, 0, 18]}>
      <Flex flex={0.3}>
        <ListenAudio
          data={exercise}
          containerProps={{
            flexDirection: 'column',
            flex: 1,
          }}
          primaryIconProp={{
            marginRight: '0',
            marginBottom: '10px',
          }}
        />
      </Flex>
      <Flex flex={0.7} flexDirection="column" gap={2}>
        <HStack border="0.5px solid #707070" minHeight={100}>
          {selectedOptions.map((option, index) => (
            <TagOption
              key={option.value}
              disabled={disabled}
              option={option}
              index={index}
              onClick={() => !disabled && onSelectedItemClick(option, index)}
            />
          ))}
        </HStack>
        <HStack>
          {mediaList.map((option, i) =>
            option ? (
              <TagOption
                option={option}
                disabled={disabled}
                separator={<HLine />}
                onClick={() => !disabled && onItemClick(option, i)}
                border={'1px solid #707070'}
                borderRadius={4}
                key={option.key + i}
                background="transparent"
              />
            ) : (
              <EmptyTag key={i} />
            )
          )}
        </HStack>
      </Flex>
    </Flex>
  )
}

export default ListenWithOrder
