import { Box, List, ListIcon, ListItem } from '@chakra-ui/react'
import React, { useState } from 'react'
import blogsIcon from 'src/asset/images/blog.svg'
import analyticsIcon from 'src/asset/images/Analytics.svg'
import entertainmentIcon from 'src/asset/images/Entertainment.svg'
import levelIcon from 'src/asset/images/Levels.svg'
import { UIConfig } from 'src/util/UIConfig'

const iconsList = {
  Levels: levelIcon,
  Entertainment: entertainmentIcon,
  Analytics: analyticsIcon,
  Blogs: blogsIcon,
}
function SideList({ activeItem, setActiveItem }) {
  //   const [activeItem, setActiveItem] = useState('levels')

  const renderList = item => {
    const list = []
    for (let key in UIConfig.sideList) {
      list.push(
        <ListItem
          padding={1}
          key={key}
          style={{ display: 'inline-flex' }}
          onClick={() => setActiveItem(key)}
          //   bgColor={item === key ? 'lightGray.600' : ''}
          color={item === key ? 'lightBlue' : ''}
        >
          <img
            style={{ marginRight: '10px' }}
            src={iconsList[UIConfig.sideList[key]]}
          />
          {UIConfig.sideList[key]}
        </ListItem>
      )
    }
    return list
  }

  return (
    <></>
    // <Box minW="18%" maxHeight="200px" margin={'0px 20px 10px 0px'} background={'#f3f1f1'}>
    //   <List py="2" spacing={3} cursor={'pointer'} display={'flex'} flexDirection={'column'}>
    //     {renderList(activeItem)}
    //   </List>
    // </Box>
  )
}

export default SideList
