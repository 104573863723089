import mission from 'src/asset/images/mission.svg'
import vision from 'src/asset/images/vision.svg'

import { isMobile } from '../CourseDetail/Practice/Util'
import {
  fontSecond,
  h1Style,
  hyperTextColor,
  imagelg,
  imagemd,
  imageSx,
} from './util'
const { Box, Flex, Text, VStack, Image, HStack } = require('@chakra-ui/react')
const SectionSeven = () => {
  return (
    <Flex
      flex={'1 0 100%'}
      section={'1'}
      bg="#fff"
      justifyContent={'center'}
      w={'100%'}
      direction={['column', 'column', 'row']}
      px={[5, 5, 5]}
      py={10}
    >
      <Box px={[5, 3, 5]} py={[10, 2, 1]} w={['100%', '100%', '50%']}>
        <VStack alignItems={['flex-start']}>
          <HStack px={[10]}>
            <Image
              borderRadius={'15px'}
              src={vision}
              sx={[imageSx, imagemd, imagelg]}
              style={{
                borderRadius: '0px',
              }}
            ></Image>
            <Text style={h1Style} pl={5} fontWeight={900}>
              {'Our Vision'}
            </Text>
          </HStack>
          {!isMobile && (
            <VStack>
              <Text>{'Our vision is to promote peace, and harmony'}</Text>
              <Text>{'and spread love in the world by increasing'}</Text>
              <Text>{'awareness and understanding among different'}</Text>
              <Text>{'communities. We consider the whole world as'}</Text>
              <Text>{'our home and different communities as our family.'}</Text> 
            </VStack>
          )}
          {isMobile && (
            <VStack>
              <Text pt={3} fontStyle={fontSecond} fontWeight={500}>
                {
                  'Our vision is to promote peace, and harmony and spread love in the world by increasing awareness and understanding of different communities. We consider the whole world as our home and different communities as our families.'
                }
              </Text>
            </VStack>
          )}
        </VStack>
      </Box>
      <Box px={[5, 3, 5]} py={[10, 2, 1]} w={['100%', '100%', '50%']}>
        <VStack>
          <HStack px={[5]} pt={[3, 3, 0]}>
            <Image
              borderRadius={'15px'}
              src={mission}
              sx={[imageSx, imagemd, imagelg]}
              style={{
                borderRadius: '0px',
              }}
            ></Image>
            <Text style={h1Style} pl={5} fontWeight={900}>
              {'Our Mission'}
            </Text>
          </HStack>
          {!isMobile && (
            <VStack>
              <Text>{'Our mission is to innovate and create joyful'}</Text>
              <Text>{'language & culture learning experiences and'}</Text>
              <Text>{'create jobs & opportunities around the world.'}</Text>
            </VStack>
          )}
          {isMobile && (
            <VStack>
              <Text pt={3} fontStyle={fontSecond} fontWeight={500}>
                {
                  'Our mission is to innovate and create joyful language & culture learning experiences and create jobs & opportunities around the world.'
                }
              </Text>
            </VStack>
          )}
        </VStack>
      </Box>
    </Flex>
  )
}
export default SectionSeven
