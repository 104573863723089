import { HStack, Image, Stack, Text, VStack } from '@chakra-ui/react'
import launch2x from 'src/asset/images/launch2x.svg'
const Analytics = () => {
  return (
    <Stack
      w={'100%'}
      alignItems={'center'}
      position={'fixed'}
      pt={'6'}
      justifyContent={'center'}
    >
      <HStack>
        <Image
          width={'48%'}
          color="blue"
          src={launch2x}
          style={{ cursor: 'pointer', paddingRight: '10px', height: '80vh' }}
        />
        <VStack>
          <Text
            fontSize={[43, 23, 43]}
            fontWeight={'900'}
            justifyContent={'flex-start'}
          >
            We're launching soon!
          </Text>
          <Text fontSize={'15px'}>
            We're currently working on this page. Get notified when we launch.
          </Text>
        </VStack>
      </HStack>
    </Stack>
  )
}
export default Analytics
