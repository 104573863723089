import { HStack } from '@chakra-ui/react'
import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation, useParams } from 'react-router'
import LearnTab from 'src/component/CourseDetail/LearnTab'
import LeftMenu from 'src/component/CourseDetail/LeftMenu'
import {
  setSelectedLesson,
  UpdateLastVisitedPage,
  UpdateUserProgress,
} from 'src/redux/actions'

import { isMobile } from './Practice/Util'

const MainContainer = () => {
  const { courseLeftMenu, loading } = useSelector(store => store.mainReducer)
  const dispatch = useDispatch()
  const { courseId, unitId, lessonId, chapterId } = useParams()
  const history = useHistory()
  const location = useLocation()

  useEffect(() => {
    function onLessonSelected(item) {
      const url = `/introduction/course/${courseId}/chapter/${item.chapterId}/unit/${unitId}/lesson/${item.lesson.id}`
      dispatch(setSelectedLesson(item))
      if (location.pathname !== url) {
        dispatch(UpdateLastVisitedPage(url))
        history.push(url)
      }
    }

    function selectLessonById(lessonId) {
      if (lessonId && courseLeftMenu?.chapters?.length > 0) {
        let lessonToSelect = {}
        for (let chapter of courseLeftMenu?.chapters) {
          if (chapter.lessons.find(item => item.id === lessonId)) {
            lessonToSelect = {
              chapterId: chapter.chapterId,
              chapter: chapter.chapterName,
              lesson: chapter.lessons.find(item => item.id === lessonId),
              unit: courseLeftMenu?.extraInfo.unitName,
              extraInfo: courseLeftMenu?.extraInfo,
            }
            break
          }
        }
        if (lessonToSelect.lesson?.id) {
          onLessonSelected(lessonToSelect)
        } else {
          let chapter = courseLeftMenu?.chapters[0]
          lessonToSelect = {
            chapterId: chapter.chapterId,
            chapter: chapter.chapterName,
            lesson: courseLeftMenu?.chapters[0]['lessons'][0],
            unit: courseLeftMenu?.extraInfo.unitName,
            extraInfo: courseLeftMenu?.extraInfo,
          }
          onLessonSelected(lessonToSelect)
        }
      } else {
        const item = {
          chapterId: chapterId,
          courseId: courseId,
          unitId: unitId,
          lessonId: lessonId,
        }
        const url = `/introduction/course/${courseId}/chapter/${item.chapterId}/unit/${unitId}/lesson/${item.lessonId}`
        dispatch(setSelectedLesson(item))
        if (location.pathname !== url) {
          dispatch(UpdateLastVisitedPage(url))
          history.push(url)
        }
      }
    }

    selectLessonById(lessonId)
  }, [lessonId, courseLeftMenu, courseId, unitId])

  const onLearnVideoEnd = useCallback(
    record => {
      dispatch(
        UpdateUserProgress({
          course: courseId,
          unit: unitId,
          chapter: record.chapterId,
          lesson: record.lesson.id,
          videoCompleted: true,
        })
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [courseId, unitId]
  )

  return (
    <>
      {/* {loading && <div className="loader1"></div>} */}
      <>
        <HStack position="relative" width="full">
          <LeftMenu alignSelf="flex-start" />
          {!isMobile && <LearnTab onLearnVideoEnd={onLearnVideoEnd} />}
        </HStack>
        {isMobile && <LearnTab onLearnVideoEnd={onLearnVideoEnd} />}
      </>
    </>
  )
}

export default MainContainer
