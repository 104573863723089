/* eslint-disable no-unused-vars */
import { VStack } from '@chakra-ui/react'
import React, { useState } from 'react'
import ListenAudio from '../HelperComponents/ListenAudio'
import RecordAudio from '../HelperComponents/RecordAudio'
import noop from 'lodash/noop'

const ListenWithRecord = ({ exercise, setSelectedAnswer }) => {
  return (
    <VStack gap={10}>
      <ListenAudio data={exercise} />
      <RecordAudio
        key={exercise.id}
        setSelectedAnswer={setSelectedAnswer}
        onStopRecording={noop}
      />
    </VStack>
  )
}

export default ListenWithRecord
