import { Text, Textarea, VStack } from '@chakra-ui/react'
import ReactStars from 'react-rating-stars-component'

const Feedback = ({ exercise, setFeedbackData, feedbackData }) => {
  const ratingChanged = newRating => {
    setFeedbackData({
      ...feedbackData,
      id: exercise?.id,
      rating: newRating.toString(),
    })
  }
  return (
    <VStack justifyContent={'center'}>
      <Text>{exercise?.feedbackQuestion}</Text>
      {exercise?.rating && (
        <ReactStars
          count={5}
          onChange={ratingChanged}
          value={feedbackData?.rating}
          size={48}
          isHalf={true}
          color="lightGray"
          emptyIcon={<i className="far fa-star"></i>}
          halfIcon={<i className="fa fa-star-half-alt"></i>}
          fullIcon={<i className="fa fa-star"></i>}
          activeColor="#2469F5"
        />
      )}
      {exercise?.comment && (
        <Textarea
          w={'550px'}
          border="1px solid #707070"
          opacity="1"
          value={feedbackData?.comment}
          placeholder={exercise?.feedbackPlaceholder}
          resize={'none'}
          borderRadius="0"
          onChange={e =>
            setFeedbackData({ ...feedbackData, comment: e.target.value })
          }
        />
      )}
    </VStack>
  )
}

export default Feedback
