import {
  Box,
  Flex,
  HStack,
  Image,
  Progress,
  Spinner,
  Stack,
  Text,
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import closeblack from 'src/asset/images/closeblack.svg'
import flagIcon from 'src/asset/images/flag.svg'
import LeftMenu from 'src/component/CourseDetail/LeftMenu'
import QuestionTypeForm from 'src/component/CourseDetail/Practice/QuestionTypeForm'
import Feedback from 'src/component/CourseDetail/Practice/QuestionTypes/Feedback'
import { isMobile } from 'src/component/CourseDetail/Practice/Util'
import ReportSection from 'src/component/ReportSection'
import PracticeNav from 'src/Container/Practice/PracticeNav'
import { useUser } from 'src/hooks/useUser'
import {
  addFeedback,
  GetLessonExerciseProgress,
  GetLessonExercises,
  GetLessonVocabularies,
  SetLessonExercises,
  SetLessonVocabularies,
  UpdateUserProgress,
} from 'src/redux/actions'

import PronounceTab from '../Pronounce'
import useExerciseProgress from './useExerciseProgress'
import { ACTIVITY_TYPE } from './usePracticeNav'
import usePracticeUtil from './usePracticeUtil'

function Practice() {
  const { isAdmin } = useUser()
  const { courseId, unitId, chapterId, lessonId } = useParams()
  const { loading, selectedLesson } = useSelector(store => store.mainReducer)
  const { user: auth } = useUser()
  const { moveToNextLesson } = usePracticeUtil()

  return (
    <PracticeInner
      {...{
        isAdmin,
        courseId,
        unitId,
        chapterId,
        lessonId,
        loading,
        selectedLesson,
        auth,
        moveToNextLesson,
      }}
    />
  )
}

export function PracticeInner({
  isAdmin,
  courseId,
  unitId,
  chapterId,
  lessonId,
  loading,
  selectedLesson,
  auth,
  moveToNextLesson,
}) {
  const dispatch = useDispatch()
  const history = useHistory()

  const [exerciseType, setExerciseType] = useState('practice')
  const [selectedAnswer, setSelectedAnswer] = useState(null)
  const [exerciseCount, setExerciseCount] = useState(0)
  const [vocabularyCount, setVocabularyCount] = useState(0)
  const [open, setOpen] = useState(false)
  const [activityScreen, setActivityScreen] = useState(ACTIVITY_TYPE.Exercise)

  const defaultFeedbackData = {
    rating: 0,
    comment: '',
    user: {
      name: auth?.firstName + ' ' + auth?.lastName,
      id: auth?._id,
      email: auth?.email,
      role: auth?.role,
    },
  }
  const [feedbackData, setFeedbackData] = useState(defaultFeedbackData)
  const { exercisesProgressData } = useExerciseProgress()
  const {
    progress,
    exerciseTodo,
    vocabularyToDo,
    isExerciseCompleted,
    isPronounceCompleted,
    isFeedbackCompleted,
  } = exercisesProgressData

  useEffect(() => {
    dispatch(SetLessonVocabularies([]))
    dispatch(SetLessonExercises([]))
    if (lessonId) {
      dispatch(GetLessonExercises(lessonId))
      dispatch(GetLessonExerciseProgress(lessonId, chapterId, unitId, courseId))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lessonId])

  // If all exercises are completed it should go to next lesson
  useEffect(() => {
    if (isFeedbackCompleted && isExerciseCompleted) moveToNextLesson()
  }, [isFeedbackCompleted, isExerciseCompleted, moveToNextLesson])

  const handleVocabulary = () => {
    setExerciseType('vocabularies')
    dispatch(GetLessonVocabularies(lessonId))
    dispatch(GetLessonExerciseProgress(lessonId, chapterId, unitId, courseId))
  }

  const handleIKnow = () => {
    setVocabularyCount(0)
    if (vocabularyToDo.length && !isAdmin) {
      dispatch(
        UpdateUserProgress({
          course: courseId,
          unit: unitId,
          chapter: chapterId,
          lesson: lessonId,
          completedVocabularyId: vocabularyToDo[vocabularyCount].id,
        })
      )
    } else if (isAdmin) {
      setVocabularyCount(
        vocabularyCount < vocabularyToDo.length - 1 ? vocabularyCount + 1 : 0
      )
    } else {
      setExerciseType('completed')
    }
  }

  const handleSubmitFeedback = async () => {
    const data = {
      ...feedbackData,
      course: {
        id: selectedLesson.extraInfo.courseId,
        name: selectedLesson.extraInfo.courseName,
      },
      unit: {
        id: selectedLesson.extraInfo.unitId,
        name: selectedLesson.extraInfo.unitName,
      },
      chapter: {
        name: selectedLesson.chapter,
        id: selectedLesson.chapterId,
      },
      lesson: {
        name: selectedLesson.lesson.name,
        id: selectedLesson.lesson.id,
      },
    }
    if (data.rating || data.comment) {
      const res = await dispatch(addFeedback(data))
      if (res) {
        setFeedbackData(defaultFeedbackData)
        dispatch(
          UpdateUserProgress({
            course: courseId,
            unit: unitId,
            chapter: chapterId,
            lesson: lessonId,
            completedFeedbackId: feedbackData?.id,
          })
        )
        moveToNextLesson()
      }
    } else {
      // todo: apply validation on form level instead
      alert('Give rating or comment')
    }
  }

  const returnPracticeNav = () => {
    return (
      <PracticeNav
        exerciseType={exerciseType}
        selectedAnswer={selectedAnswer}
        isAdmin={isAdmin}
        handleIKnow={handleIKnow}
        exerciseTodo={exerciseTodo}
        setSelectedAnswer={setSelectedAnswer}
        exerciseCount={exerciseCount}
        setExerciseCount={setExerciseCount}
        setVocabularyCount={setVocabularyCount}
        handleVocabulary={handleVocabulary}
        isExerciseCompleted={isExerciseCompleted}
        isPronounceCompleted={isPronounceCompleted}
        progress={progress}
        vocabularyToDo={vocabularyToDo}
        vocabularyCount={vocabularyCount}
        isFeedbackCompleted={isFeedbackCompleted}
        handleSubmitFeedback={handleSubmitFeedback}
        activityScreen={activityScreen}
        setActivityScreen={setActivityScreen}
      />
    )
  }

  const currentExercise = exerciseTodo[exerciseCount]

  console.log(currentExercise, activityScreen)
  const onBackImageClick = () => {
    history.push(`/coursedetails/${unitId}`)
  }

  let lessonFeedback
  if (
    selectedLesson &&
    selectedLesson.lesson &&
    selectedLesson.lesson.lessonFeedback !== null
  ) {
    lessonFeedback = selectedLesson?.lesson?.lessonFeedback[0]
  }

  return (
    <>
      <Flex
        direction={['column', 'column', 'row']}
        gap={['10px', '10px', '30px']}
        pb={['20px', '20px', '100px']}
        alignItems="flex-start"
      >
        {!isMobile && <LeftMenu />}
        <Stack mx="auto" w={'75%'} gap="1" paddingTop={'15px'}>
          {!isAdmin && activityScreen !== ACTIVITY_TYPE.Feedback && (
            <Box data-testid="progress-bar">
              <HStack p={'relative'} w={'100%'}>
                <Text
                  fontWeight={'bold'}
                  fontSize={'md'}
                >{`${progress} %`}</Text>

                <Progress
                  borderRadius={5}
                  colorScheme="blue"
                  size="md"
                  value={progress}
                  flex="1"
                />
                <Image
                  src={closeblack}
                  cursor={'pointer'}
                  onClick={onBackImageClick}
                />
                <Image
                  src={flagIcon}
                  alt="Report Issue Langistan"
                  boxSize="25px"
                  style={{
                    position: 'absolute',
                    right: 10,
                  }}
                  cursor={'pointer'}
                  onClick={() => {
                    setOpen(true)
                  }}
                />
              </HStack>
            </Box>
          )}
          {/* 
          {loading && (
            <Stack gap={2}>
              <Spinner
                size="xl"
                position="absolute"
                top="50%"
                zIndex="999"
                left="50%"
              />
            </Stack>
          )}
          */}
          {isAdmin && (
            <Flex
              justifyContent={'flex-end'}
              marginBottom={'-40px'}
              zIndex={999}
            >
              <Image
                src={flagIcon}
                alt="Report Issue Langistan"
                boxSize="25px"
                cursor={'pointer'}
                onClick={() => {
                  setOpen(true)
                }}
              ></Image>
            </Flex>
          )}

          {activityScreen === ACTIVITY_TYPE.Feedback &&
          (lessonFeedback?.rating || lessonFeedback?.comment) ? (
            <Feedback
              setFeedbackData={setFeedbackData}
              feedbackData={feedbackData}
              exercise={lessonFeedback}
            />
          ) : null}

          {!isExerciseCompleted && activityScreen === ACTIVITY_TYPE.Exercise ? (
            <Stack
              className="questionContainer"
              gap={2}
              data-testid="questionContainer"
            >
              <Text fontSize="lg">
                {currentExercise?.question || currentExercise?.name}
              </Text>
              <QuestionTypeForm
                exercise={currentExercise}
                setSelectedAnswer={setSelectedAnswer}
                selectedAnswer={selectedAnswer}
                data-testid="questionForm"
              />
            </Stack>
          ) : null}

          {activityScreen === ACTIVITY_TYPE.Vocabulary ? (
            <>
              <PronounceTab
                lessonVocabularies={vocabularyToDo[vocabularyCount]}
                vocabularyCount={vocabularyCount}
                setVocabularyCount={setVocabularyCount}
              />
            </>
          ) : null}
        </Stack>
      </Flex>
      {open && (
        <ReportSection
          open={open}
          setOpen={setOpen}
          selectedExercise={currentExercise}
          selectedVocabularies={vocabularyToDo[vocabularyCount]}
        ></ReportSection>
      )}
      {returnPracticeNav()}
    </>
  )
}

export default Practice
