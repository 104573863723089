import {
  Box,
  Button,
  Flex,
  FormControl,
  Link,
  Text,
  useToast,
} from '@chakra-ui/react'
import { Form, Formik } from 'formik'
import { useState } from 'react'
import { useHistory } from 'react-router'
import { useAuth } from 'src/context/AuthContext'
import { CheckboxField, EmailField, PasswordField } from 'src/lib/form/fields'

import * as myConstClass from './constants'
const api_Url = myConstClass.api

const LoginForm = () => {
  const [showPassword, setShowPassword] = useState(false)
  const history = useHistory()
  const toast = useToast()
  const auth = useAuth()

  let userEmall = ''

  const onSignUpClick = () => {
    history.push('/register')
  }

  const onForgotPasswordClick = () => {
    if (userEmall.length <= 0) {
      toast({
        title: 'Warning.',
        description: 'Please enter email addrees on login form.',
        status: 'warning',
        duration: 5000,
        isClosable: true,
      })
    } else {
      let data = {
        email: userEmall,
        url: window.location.origin,
        timeStamp: new Date(new Date().getTime() + 20 * 60000).toString(),
      }
      fetch(api_Url + '/resetPassword', {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
          return response.json()
        })
        .then(response => {
          let title = 'Success'
          let message = ''
          let status = 'success'
          if (response.code === 200) {
            message =
              'We have successfully sent the password reset link to your email, please check'
          } else {
            title = 'Warning'
            status = 'warning'
            message = response.message
          }
          toast({
            title: title,
            description: message,
            status: status,
            duration: 9000,
            isClosable: true,
          })
        })
    }
  }

  const onSubmit = async (
    { email, password, rememberMe, ...values },
    { setSubmitting }
  ) => {
    try {
      const query = new URLSearchParams(window.location.search)
      const fallbackUrl = query.get('fallbackUrl')
      const res = await auth.login({ email, password, fallbackUrl })
      if (res?.fallbackUrl) {
        history.push(res?.fallbackUrl)
      } else {
        history.push(`/language`)
      }
    } catch (error) {
      console.log('Error on login', error)
    }
  }

  return (
    <Flex flexDirection="column">
      <Formik
        initialValues={{ email: '', password: '', rememberMe: false }}
        validate={values => {
          const errors = {}
          userEmall = values.email
          if (!values.email) {
            errors.email = 'Required'
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          ) {
            errors.email = 'Invalid email address'
          }

          if (values.password === '') {
            errors.password = 'Required'
          }

          return errors
        }}
        onSubmit={onSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            <Flex
              mt={0.5}
              mb={7}
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              // marginTop={4}
            >
              <Text fontSize="md" fontWeight="bold">
                Login to Langistan
              </Text>
              <Text fontSize="xs">Please enter your login details</Text>
            </Flex>

            <Flex gap={18} flexDirection="column">
              <FormControl>
                <EmailField
                  type="email"
                  height={50}
                  id="email"
                  name="email"
                  placeholder="Email"
                />
              </FormControl>
              <PasswordField
                id="password"
                name="password"
                placeholder="Password"
                value={showPassword}
                onChange={setShowPassword}
              />
              <Flex alignItems="center">
                {/* todo: Remember me func  */}
                <CheckboxField id="rememberMe" name="rememberMe" noBackground>
                  Remember me
                </CheckboxField>
                {/* todo: forgot password func  */}
                <Link color="#3E4CCE" href="#" onClick={onForgotPasswordClick}>
                  Forgot password
                </Link>
              </Flex>
              <Button
                type="submit"
                isLoading={isSubmitting}
                loadingText="Submitting"
                variant="primary"
                width="100%"
                style={{
                  height: '48px',
                }}
              >
                Login
              </Button>
              <Text textAlign="center">
                Don't have an account?
                <Link
                  marginLeft={1}
                  color="#3E4CCE"
                  href="#"
                  onClick={onSignUpClick}
                >
                  Sign up
                </Link>
              </Text>
              <Text textAlign="center">
                <Link
                  marginLeft={1}
                  color="#3E4CCE"
                  href="mailto:support@langistan.com" //TODO
                >
                  Connect Us
                </Link>
              </Text>
            </Flex>

            <Box h={'60px'}></Box>
          </Form>
        )}
      </Formik>
    </Flex>
  )
}

export default LoginForm
