import { compact } from 'lodash'

export function getExerciseProgress(
  lessonExercises,
  lessonExercisesProgress,
  lessonVocabularies,
  lessonFeedback = []
) {
  const toReturn = {}
  lessonExercises = compact(
    lessonExercises.map(exercise =>
      exercise.isActive !== false || exercise.isActive !== undefined
        ? exercise
        : null
    )
  )

  if (!lessonExercisesProgress) {
    return {
      exerciseCompleted: false,
      progress: 0,
      exerciseTodo: lessonExercises,
      vocabularyToDo: lessonVocabularies,
      isExerciseCompleted: false,
      isPronounceCompleted: false,
      isFeedbackCompleted: lessonFeedback?.length === 0,
    }
  }

  const completedExerciseIds =
    lessonExercisesProgress.completedExerciseIds || []
  toReturn.exerciseTodo = compact(
    lessonExercises.map(exercise =>
      !completedExerciseIds.includes(exercise.id) &&
      (exercise.isActive !== false || exercise.isActive !== undefined)
        ? exercise
        : null
    )
  )

  toReturn.vocabularyToDo = compact(
    lessonVocabularies.map(vocabulary =>
      !lessonExercisesProgress.completedVocabularyIds.includes(vocabulary.id)
        ? vocabulary
        : null
    )
  )

  // todo: fetching from API computed column.

  toReturn.progress = lessonExercisesProgress.progress || 0

  toReturn.exerciseCompleted = toReturn.progress === 100
  toReturn.isExerciseCompleted =
    lessonExercisesProgress.completedExerciseIds?.length ===
    lessonExercisesProgress.totalExerciseIds.length
  toReturn.isPronounceCompleted =
    lessonExercisesProgress.completedVocabularyIds?.length ===
    lessonExercisesProgress.totalVocabularyIds.length
  toReturn.isFeedbackCompleted =
    lessonFeedback.length === 0 ||
    lessonExercisesProgress.completedFeedbackIds?.length ===
      lessonExercisesProgress.totalFeedbackIds.length
  return toReturn
}

// todo: this util function will be a common for vcab and practic later
export const getVocabulariesProgress = (
  lessonVocabularies,
  lessonExercisesProgress
) => {
  const toReturn = {}
  if (!lessonExercisesProgress) {
    return {
      vocabularyCompleted: false,
      vocabulariesToDo: lessonVocabularies,
    }
  }

  const completedVocabularyeIds =
    lessonExercisesProgress?.completedVocabularyIds || []

  toReturn.vocabulariesToDo = compact(
    lessonVocabularies.map(exercise =>
      !completedVocabularyeIds.includes(exercise.id) ? exercise : null
    )
  )

  toReturn.vocabularyCompleted =
    lessonExercisesProgress?.completedVocabularyIds.length ===
    lessonVocabularies.length
  return toReturn
}

export function getFileUrl(fileName) {
  // return `${config.API_URI}download/${fileName}`
  if (fileName.includes('base64')) return fileName
  return `https://cdn.langistan.com/media/${fileName}`
}

export const isMobileFn = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  )
}
export const isMobile = isMobileFn()
