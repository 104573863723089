import { isEmpty } from 'lodash'
import { useEffect, useState } from 'react'
import { extractOptions } from 'src/util/helpers'
import useMediaOptions from './useMediaOptions'

const getAnswerFromOptions = options => {
  let newSelectedOption = ''
  options.forEach(option => (newSelectedOption += option.value.trim() + ' '))
  return newSelectedOption.trim()
}
const useWithOrder = ({
  exercise,
  setSelectedAnswer,
  selectedAnswer,
  hasAudio,
}) => {
  const [selectedOptions, setSelectedOptions] = useState([])
  const { mediaList, setMediaList, onAudioPlay } = useMediaOptions({
    exercise,
    hasAudio,
  })

  useEffect(() => {
    setSelectedAnswer(getAnswerFromOptions(selectedOptions))
  }, [selectedOptions, setSelectedAnswer])

  useEffect(() => {
    if (
      isEmpty(selectedAnswer) &&
      selectedAnswer !== getAnswerFromOptions(selectedOptions)
    ) {
      setSelectedOptions([])
      setMediaList(extractOptions(exercise?.options))
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAnswer])

  const onItemClick = (option, index) => {
    onAudioPlay(option.nativeFile)
    const newSelectedOptions = [...selectedOptions]
    newSelectedOptions.push({ ...option, index })
    setSelectedOptions(newSelectedOptions)
    mediaList[index] = null
    setMediaList([...mediaList])
  }

  const onSelectedItemClick = (option, itemIndex) => {
    onAudioPlay(option.nativeFile)
    const index = option.index
    const newSelectedOptions = [...selectedOptions]
    newSelectedOptions.splice(itemIndex, 1)
    setSelectedOptions(newSelectedOptions)

    mediaList[index] = { ...option, index }
    setMediaList([...mediaList])
  }

  return {
    mediaList,
    selectedOptions,
    onItemClick,
    onSelectedItemClick,
  }
}

export default useWithOrder
