import {
  Button,
  Input,
  InputGroup,
  InputLeftAddon,
  Stack,
} from '@chakra-ui/react'
import moment from 'moment'
import React from 'react'
import { useHistory } from 'react-router'
import useSubscription from 'src/hooks/useSubscription'

export default function UserSubscription() {
  const { subscription, manageSubscrption, cancelSubscrption } = useSubscription()
  const history = useHistory()

  const handleCancelSubscription = async() => {
    const subscriptionRes = await cancelSubscrption();
    debugger
    if(subscriptionRes.code === 200 && !subscriptionRes.error) {
      alert("Subscription cancelled successfully");
      history.push('/language')
      window.location.reload()
    }
  }

  return (
    <Stack gap="1">
      <InputGroup>
        <InputLeftAddon
          p={0}
          justifyContent="center"
          children="Plan Name"
          w={'180px'}
        />
        <Input readOnly value={subscription?.plan?.nickname || 'Free Plan'} />
      </InputGroup>
      <InputGroup>
        <InputLeftAddon
          p={0}
          justifyContent="center"
          children="From Date"
          w={'180px'}
        />
        <Input
          readOnly
          placeholder="From Date"
          value={
            subscription?.current_period_start &&
            `${moment(subscription.current_period_start * 1000).format(
              'DD-MMM-YYYY'
            )}`
          }
        />
      </InputGroup>
      <InputGroup>
        <InputLeftAddon
          p={0}
          justifyContent="center"
          children="To Date"
          w={'180px'}
        />
        <Input
          readOnly
          placeholder="To Date"
          value={
            subscription?.current_period_end &&
            `${moment(subscription.current_period_end * 1000).format(
              'DD-MMM-YYYY'
            )}`
          }
        />
      </InputGroup>
      {subscription ? (
        <>
          <Button
            width={'unset'}
            mx="auto"
            variant={'primary'}
            onClick={manageSubscrption}
          >
            Manage Subscription
          </Button>
          <Button
            width={'unset'}
            mx="auto"
            variant={'primary'}
            onClick={handleCancelSubscription}
          >
            Cancel Subscription
          </Button>
        </>
      ) : (
        <Button
          width={'unset'}
          mx="auto"
          variant={'primary'}
          onClick={() => history.push('/pricing')}
        >
          Upgrade Plan
        </Button>
        // <div>{JSON.stringify(user)}</div>
      )}
    </Stack>
  )
}
