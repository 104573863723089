import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getExerciseProgress } from 'src/component/CourseDetail/Practice/Util'

function useExerciseProgress() {
  const lessonFeedback = useSelector(state => state.mainReducer.lessonFeedback)
  const lessonExercises = useSelector(
    state => state.mainReducer.lessonExercises
  )
  const lessonExercisesProgress = useSelector(
    state => state.mainReducer.lessonExercisesProgress
  )
  const lessonVocabularies = useSelector(
    state => state.mainReducer.lessonVocabularies
  )

  const [exercisesProgressData, setExercisesProgress] = useState({
    progress: 0,
    exerciseTodo: {},
    exerciseCompleted: false,
    vocabularyToDo: {},
    isFeedbackCompleted: true,
  })

  useEffect(() => {
    if (lessonExercisesProgress) {
      const data = getExerciseProgress(
        lessonExercises,
        lessonExercisesProgress,
        lessonVocabularies,
        lessonFeedback
      )
      setExercisesProgress(data)
    }
  }, [
    lessonExercisesProgress,
    lessonExercises,
    lessonVocabularies,
    lessonFeedback,
  ])

  return {
    exercisesProgressData,
  }
}

export default useExerciseProgress
