import { Divider,Flex, HStack } from '@chakra-ui/react'

import WatchVideo from '../HelperComponents/WatchVideo'
import HLine from './HLine'
import TagOption, { EmptyTag } from './TagOption'
import useWithOrder from './useWithOrder'

const WatchWithOrder = ({ exercise, setSelectedAnswer, disabled }) => {
  const { mediaList, selectedOptions, onItemClick, onSelectedItemClick } =
    useWithOrder({ exercise, setSelectedAnswer })

  return (
    <Flex padding={18} direction={['column', 'column', 'row']} gap={10}>
      <Flex flex={0.3}>
        <WatchVideo exercise={exercise} />
      </Flex>
      <Flex flex={0.7} flexDirection="column" gap={2}>
        <Divider position={"relative"} borderWidth="0.1px" top={["60px", "60px", "76px"]}/>
        <HStack border="1px solid #707070" minHeight={120}>
          {selectedOptions.map((option, index) => (
            <TagOption
              key={option.value}
              disabled={disabled}
              option={option}
              index={index}
              onClick={() => !disabled && onSelectedItemClick(option, index)}
            />
          ))}
        </HStack>
        <HStack>
          {mediaList.map((option, i) =>
            option ? (
              <TagOption
                option={option}
                disabled={disabled}
                separator={<HLine />}
                onClick={() => !disabled && onItemClick(option, i)}
                border={'1px solid #707070'}
                borderRadius={4}
                key={option.key + i}
                background="transparent"
              />
            ) : (
              <EmptyTag key={i} />
            )
          )}
        </HStack>
      </Flex>
    </Flex>
  )
}

export default WatchWithOrder
