/* eslint-disable no-throw-literal */
// pretend this is firebase, netlify, or auth0's code.
// you shouldn't have to implement something like this in your own app
import { tokenStore } from 'src/redux/useTokenStore'
import API from 'src/util/api'
import { API_URI } from 'src/util/config'

async function getToken() {
  // if we were a real auth provider, this is where we would make a request
  // to retrieve the user's token. (It's a bit more complicated than that...
  // but you're probably not an auth provider so you don't need to worry about it).
  return tokenStore.getState().token
}

function handleUserResponse({ token }) {
  tokenStore.setState({ token })
  return token
}

async function login({ email, password, fallbackUrl }) {
  const genericErrorMessage = 'Something went wrong! Please try again later.'
  const response = await fetch(API_URI + '/newlogin', {
    method: 'POST',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ email, password, fallbackUrl }),
  })
  if (!response.ok) {
    if (response.status === 400) {
      throw 'Please fill all the fields correctly!'
    } else if (response.status === 401) {
      throw 'Invalid email and password combination.'
    } else {
      throw genericErrorMessage
    }
  }

  const data = await response.json()
  handleUserResponse(data)
  return data
}

function register({ email, password, firstName }) {
  return client('newsignup', { email, password, firstName }).then(
    handleUserResponse
  )
}

async function logout() {
  return API.get('logout').then(data => {
    handleUserResponse(data)
  })
}

// an auth provider wouldn't use your client, they'd have their own
// so that's why we're not just re-using the client
function client(endpoint, data, method = 'post') {
  return API.post(`${endpoint}`, data)
}

export { getToken, login, logout, register }
