import { Box, Flex, HStack, Image, Text } from '@chakra-ui/react'
import Lottie from 'lottie-react'
import React, { useRef } from 'react'
import SpeechRecognition, {
  useSpeechRecognition,
} from 'react-speech-recognition'
import pause from 'src/asset/images/pause.svg'
import record from 'src/asset/images/record.svg'
import restart from 'src/asset/images/restart.svg'
import RecordingData from 'src/asset/lottie-json/recording_pause.json'

const IconSize = 70

const RecordAudio = ({ onStopRecording, setSelectedAnswer }) => {
  const lottieRef = useRef()

  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
    isMicrophoneAvailable,
  } = useSpeechRecognition()

  const onReset = () => {
    SpeechRecognition.stopListening()
    setSelectedAnswer('')
    resetTranscript()
  }

  const onStartListening = async () => {
    await SpeechRecognition.startListening({
      language: 'Hi-IND',
      continuous: true,
    })

    // Stop recording after 10 seconds
    setTimeout(() => {
      if (listening) stopRecording()
    }, 10 * 1000)
  }

  async function stopRecording() {
    if (!listening) return

    await SpeechRecognition.stopListening()
    onStopRecording(transcript)
    setSelectedAnswer(transcript)
  }

  if (!browserSupportsSpeechRecognition) {
    return <Text>Browser doesn't support speech recognition.</Text>
  }

  if (!isMicrophoneAvailable) {
    return <Text>Microphone not found.</Text>
  }

  const resetIconSize = IconSize - 10

  return (
    <Flex
      direction={'column'}
      gap={2}
      justifyContent="center"
      mb={['100px', '100px', '0px']}
    >
      <Box
        w={['300px', '300px', '550px']}
        border="1px solid #707070"
        opacity="1"
        resize={'none'}
        p={2}
        minHeight={'80px'}
      >
        {transcript}
      </Box>
      <HStack justifyContent={'center'}>
        {transcript && <Box boxSize={resetIconSize + 'px'} />}
        {listening ? (
          <Box
            boxSize={IconSize + 'px'}
            cursor="pointer"
            position="relative"
            onClick={() => {
              stopRecording()
            }}
          >
            <Lottie animationData={RecordingData} lottieRef={lottieRef} />
            <Image
              src={pause}
              h="15px"
              w="25px"
              alt="pause"
              top="0"
              left="0"
              right="0"
              bottom="0"
              margin="auto"
              position="absolute"
            />
          </Box>
        ) : (
          <Image
            title="Record voice"
            onClick={onStartListening}
            boxSize={IconSize + 'px'}
            src={record}
            marginRight={1}
          />
        )}
        {transcript && (
          <Image
            title="Reset"
            onClick={onReset}
            boxSize={resetIconSize + 'px'}
            src={restart}
            aria-hidden="true"
            marginRight={1}
          />
        )}
      </HStack>
    </Flex>
  )
}

export default React.memo(RecordAudio)
