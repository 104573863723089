import { useState, useEffect, useRef } from 'react'
import { extractOptions } from 'src/util/helpers'
import { getFileUrl } from '../Util'

const useMediaOptions = ({ exercise, hasAudio, onAudioStop }) => {
  const audioRef = useRef()
  const [mediaList, setMediaList] = useState([])

  useEffect(() => {
    setMediaList(extractOptions(exercise?.options))
  }, [exercise])

  const onAudioPlay = url => {
    if (!url) {
      return
    }

    if (hasAudio) {
      if (audioRef.current) {
        audioRef.current.pause()
      }

      const audio = new Audio(getFileUrl(url))
      if (onAudioStop) audio.addEventListener('ended', onAudioStop)

      audio.play()
      audioRef.current = audio
    }
  }

  return {
    mediaList,
    setMediaList,
    onAudioPlay,
  }
}

export default useMediaOptions
