import { Box } from '@chakra-ui/react'
import React from 'react'

import Toolbar from './component/Toolbar'

function Layout({ children }) {
  return (
    <Box>
      <Toolbar />
      {children}
    </Box>
  )
}

export default Layout
