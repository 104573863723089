import { Carousel } from '3d-react-carousal'
import { useState } from 'react'
import four from 'src/asset/images/sectioneight/four.jpg'
import one from 'src/asset/images/sectioneight/one.jpg'
import three from 'src/asset/images/sectioneight/three.jpg'
import two from 'src/asset/images/sectioneight/two.jpg'

import { isMobile } from '../CourseDetail/Practice/Util'
import { hyperTextColor, imagelg, imagemd, imageSx } from './util'

const { Box, Flex, Text, Image, VStack } = require('@chakra-ui/react')
let slides = [
  <Image
    sx={[imageSx, imagemd, imagelg]}
    borderRadius={'15px'}
    src={one}
    alt="1"
  />,
  <Image
    sx={[imageSx, imagemd, imagelg]}
    borderRadius={'15px'}
    src={two}
    alt="2"
  />,
  <Image
    sx={[imageSx, imagemd, imagelg]}
    borderRadius={'15px'}
    src={three}
    alt="3"
  />,
  <Image
    sx={[imageSx, imagemd, imagelg]}
    borderRadius={'15px'}
    src={four}
    alt="4"
  />,
]
const SectionEight = () => {
  const [autoPlay, setAutoPlay] = useState(false)
  return (
    <VStack
      bg={'#fff'}
      alignItems={'center'}
      flex={'1 0 100%'}
      section={'1'}
      direction={['column']}
      w={'100%'}
      px={[5, 5, 5]}
      py={2}
    >
      <Box>
        <Text
          color={'{hyperTextColor}'}
          w="100%"
          textAlign={'center'}
          letterSpacing={[2, 1, 2]}
          pb={[0, 0, 2]}
          fontSize={['15px', '20px', '28px', '28px']}
          style={{
            color: '#2469F5',
          }}
          fontWeight={['900']}
        >
          {'Who are we?'}
        </Text>
        {!isMobile && (
          <>
            <Text
              w="100%"
              textAlign={'center'}
              fontSize={['16px']}
              fontWeight={[400]}
              px={3}
            >
              {
                'We’re a group of professional language teachers, programmers & data-scientists committed to'
              }
            </Text>
            <Text
              w="100%"
              textAlign={'center'}
              fontSize={['16px']}
              fontWeight={[400]}
              px={3}
            >
              {
                'making learning languages joyful, time-saving and effective. We aim to go beyond sharing cultural'
              }
            </Text>
            <Text
              w="100%"
              textAlign={'center'}
              fontSize={['16px']}
              fontWeight={[400]}
              px={3}
              pb={5}
            >
              {'aspects of the language you are learning.'}
            </Text>
          </>
        )}
        {isMobile && (
          <Text
            w="100%"
            textAlign={'center'}
            fontSize={['16px']}
            fontWeight={[400]}
            px={3}
            py={5}
          >
            {
              'We’re a group of professional language teachers, programmers & data-scientists committed to making learning languages joyful, time-saving and effective. We aim to go beyond sharing cultural aspects of the language you are learning.'
            }
          </Text>
        )}
      </Box>
      <Box w={['100%']}>
<Carousel slides={slides} autoplay={true} interval={3000} />
      </Box>
    </VStack>
  )
}
export default SectionEight
