import React from 'react'
import { ExcerciseTypes } from 'src/util/helpers'

import ListenWithAudio from './QuestionTypes/ListenWithAudio'
import ListenWithImage from './QuestionTypes/ListenWithImage'
import ListenWithOrder from './QuestionTypes/ListenWithOrder'
import ListenWithRecord from './QuestionTypes/ListenWithRecord'
import ListenWithText from './QuestionTypes/ListenWithText'
import ListenWithType from './QuestionTypes/ListenWithType'
import WatchVideoOnly from './QuestionTypes/WatchVideoOnly'
import WatchWithAudio from './QuestionTypes/WatchWithAudio'
import WatchWithOrder from './QuestionTypes/WatchWithOrder'
import WatchWithRecord from './QuestionTypes/WatchWithRecord'
import WatchWithText from './QuestionTypes/WatchWithText'
import WatchWithType from './QuestionTypes/WatchWithType'
const getComponent = excerciseType => {
  switch (excerciseType) {
    case ExcerciseTypes.LISTEN_WITH_IMAGE:
      return ListenWithImage
    case ExcerciseTypes.LISTEN_WITH_AUDIO:
      return ListenWithAudio
    case ExcerciseTypes.LISTEN_WITH_ORDER:
      return ListenWithOrder
    case ExcerciseTypes.LISTEN_WITH_RECORD:
      return ListenWithRecord
    case ExcerciseTypes.LISTEN_WITH_TEXT:
      return ListenWithText
    case ExcerciseTypes.LISTEN_WITH_TYPE:
      return ListenWithType
    case ExcerciseTypes.WATCH_WITH_AUDIO:
      return WatchWithAudio
    case ExcerciseTypes.WATCH_4LVIDEO:
      return () => 'WATCH 4LVIDEO type is in Progress'
    case ExcerciseTypes.WATCH_WITH_TYPE:
      return WatchWithType
    case ExcerciseTypes.WATCH_WITH_TEXT:
      return WatchWithText
    case ExcerciseTypes.WATCH_WITH_ORDER:
      return WatchWithOrder
    case ExcerciseTypes.WATCH_WITH_RECORD:
      return WatchWithRecord
    case ExcerciseTypes.WATCH_VIDEO:
      return WatchVideoOnly
    default:
      return null
  }
}

const QuestionTypeForm = props => {
  const Component = getComponent(props.exercise?.questionType)
  return Component ? <Component {...props} /> : null
}

export default QuestionTypeForm
