import { useFlags } from 'src/redux/useFeatureFlags'
import API from 'src/util/api'
import useSWR, { useSWRConfig } from 'swr'

import { useUser } from './useUser'

const stripeSubscriptionFetcher = customerId => url =>
  API.get(`${url}?customerId=${customerId}`)

const rzpSubscriptionFetcher = subscriptionId => url =>
  API.get(`${url}?subscriptionId=${subscriptionId}`)

function getSubscrptionFetcher(user, razorPayEnabled) {
  if (razorPayEnabled) {
    return user?.rzpSubscriptionId
      ? rzpSubscriptionFetcher(user?.rzpSubscriptionId)
      : null
  } else {
    return user?.stripeCustomerId
      ? stripeSubscriptionFetcher(user?.stripeCustomerId)
      : null
  }
}

function getSubscriptionUrl(user, razorPayEnabled) {
  if (razorPayEnabled) {
    return 'get-subscription-rzp'
  }
  return 'get-subscription'
}

export default function useSubscription({ enableRazorPay = false } = {}) {
  // const { RAZORPAY_ENABLED = enableRazorPay } = useFlags()
  const RAZORPAY_ENABLED = enableRazorPay
  const { user } = useUser()
  const { cache } = useSWRConfig()

  const fetcher = getSubscrptionFetcher(user, RAZORPAY_ENABLED)
  const subscriptionUrl = getSubscriptionUrl(user, RAZORPAY_ENABLED)

  const subscriptionData = cache.get(subscriptionUrl)

  const { data, error } = useSWR(
    fetcher && !subscriptionData ? subscriptionUrl : null,
    fetcher
  )

  if (user && !user.stripeCustomerId && !user.rzpSubscriptionId) {
    return {
      isLoading: false,
      isSubscriptionActive: user.enrollments?.length,
      isOldSubscription: true,
      error: null,
    }
  }

  async function manageSubscrption() {
    if (RAZORPAY_ENABLED) {
      alert('need to add manage subscription')

      return
    }

    if (!user?.stripeCustomerId) {
      return
    }

    const data = {
      customerId: user.stripeCustomerId,
    }
    const res = await API.post('create-billing-session', data)
    window.location.href = res.url
  }

  const respData = data || subscriptionData

  const subscription = getSubscriptionData(respData, RAZORPAY_ENABLED)

  async function cancelSubscrption() {
    if (RAZORPAY_ENABLED) {
      alert('need to add cancel subscription')
      return
    }

    if (!user?.stripeCustomerId) {
      return
    }

    const res = await API.delete(`cancel-subscription/${subscription?.id || "asd"}`)
    return res;
  }

  return {
    isLoading: !respData && !error,
    isSubscriptionActive: subscription?.status === 'active',
    isOldSubscription: false,
    error,
    subscription,
    manageSubscrption,
    cancelSubscrption,
  }
}

function getSubscriptionData(data, razorPayEnabled) {
  if (razorPayEnabled) {
    return data
  }
  return data?.subscriptions?.data?.[0]
}
