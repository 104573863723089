import { HStack } from '@chakra-ui/react'
import WatchVideo from '../HelperComponents/WatchVideo'

const WatchVideoOnly = ({ exercise, setSelectedAnswer }) => {
  const onProgressCompleted = () => {
    setSelectedAnswer(true)
  }
  return (
    <HStack justifyContent="center" alignItems="center">
      <WatchVideo
        exercise={exercise}
        onProgressCompleted={onProgressCompleted}
        width={700}
        height={390}
      />
    </HStack>
  )
}

export default WatchVideoOnly
