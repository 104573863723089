import { useState } from 'react'
import { useHistory } from 'react-router'
import sectiontwoone from 'src/asset/images/sectiontwo/sectiontwoone.jpg'
import sectiontwothree from 'src/asset/images/sectiontwo/sectiontwothree.jpg'
import sectiontwotwo from 'src/asset/images/sectiontwo/sectiontwotwo.jpg'

import { isMobile } from '../CourseDetail/Practice/Util'
import ImageSlider from './ImageSlider'
import {
  btnHover,
  fontMain,
  fontSecond,
  hyperTextColor,
  imageFlex,
  imagelg,
  imagemd,
  imageSx,
  textPanelWidth,
} from './util'

const { Box, Flex, Text, Button } = require('@chakra-ui/react')
const selctionTwoData = [
  {
    image: sectiontwotwo,
  },
  {
    image: sectiontwothree,
  },
  {
    image: sectiontwoone,
  },
]

const height = '40px'
const width = isMobile ? '180px' : '150px'

const SectionTwo = () => {
  const history = useHistory()
  const [autoPlay, setAutoPlay] = useState(false)
  const onSignUp = () => {
    history.push({
      pathname: '/register',
      state: {
        comingFromHome: true,
      },
    })
  }
  return (
    <Flex
      flex={'1 0 100%'}
      section={'1'}
      bg="#fff"
      w={'100%'}
      direction={['column', 'column-reverse', 'row']}
      px={[5, 5, 5]}
      py={5}
      justifyContent={'center'}
    >
      <Box px={[10, 0, 10]} py={[10, 2, 5]} w={textPanelWidth}>
        {isMobile && (
          <>
            <Text
              textAlign={'left'}
              fontSize={fontMain}
              fontWeight={900}
              pb={3}
            >
              {
                ' The only way to remember and speak is by practicing enough and repeating you,  until its a reflex action for you.'
              }
            </Text>
          </>
        )}
        {!isMobile && (
          <>
            <Text textAlign={'left'} fontSize={fontMain} fontWeight={900}>
              The only way to
              <Text
                bg="{hyperTextColor}"
                //  color={'#fff'}
                display={'inline'}
                fontWeight={900}
                // px={2}
                fontSize={fontMain}
              >
                {' remember and speak'}
              </Text>
            </Text>
            <Text
              textAlign={'left'}
              fontSize={fontMain}
              fontWeight={900}
              pb={5}
            >
              {
                '  is by practicing enough and repeating you,  until its a reflex action for you.'
              }
            </Text>
          </>
        )}
        {isMobile && (
          <>
            <Text
              textAlign={'left'}
              pb={0}
              fontSize={fontSecond}
              fontWeight={600}
            >
              {
                'Our 27+ courses & 3000+ practice modules for reading,writing, speaking & listening will help you achieve this.'
              }
            </Text>{' '}
          </>
        )}
        {!isMobile && (
          <>
            <Text
              textAlign={'left'}
              pb={0}
              fontSize={fontSecond}
              fontWeight={600}
            >
              {'Our 27+ courses & 3000+ practice modules for reading,'}
            </Text>
            <Text
              textAlign={'left'}
              pb={0}
              fontSize={fontSecond}
              fontWeight={600}
            >
              {'writing, speaking & listening will help you achieve this.'}
            </Text>
          </>
        )}

        <Text textAlign={'left'} pt={3} fontSize={fontSecond} fontWeight={600}>
          {'Langistan is built on '}
          <Text
            bg="{hyperTextColor}"
            // color={'#fff'}
            display={'inline'}
            fontWeight={900}
            // px={2}
            fontSize={fontSecond}
          >
            {'spaced - repetition system'}
          </Text>
        </Text>
        <Box pt={[5, 5, 3]} textAlign={['center', 'center', 'inherit']}>
          <Button
            color="#707070"
            w={width}
            h={height}
            //bg={btnBg}
            style={{
              border: '1px solid #707070',
              backgroundColor: '#fff',
            }}
            borderRadius={'5px'}
            _hover={btnHover}
            onClick={onSignUp}
          >
            Sign up Now
          </Button>
        </Box>
      </Box>
      <Box
        textAlign={['center', 'left', 'left']}
        w={['90%', '100%', '30%']}
        flex={imageFlex}
        py={5}
      >
        <ImageSlider
          px={[10, 0, 0]}
          sx={[imageSx, imagemd, imagelg]}
          borderRadius={'15px'}
          slides={selctionTwoData}
          autoPlay={autoPlay}
          onClick={() => {
            setAutoPlay(!autoPlay)
          }}
        ></ImageSlider>
      </Box>
    </Flex>
  )
}

export default SectionTwo
