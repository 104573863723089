import { useState } from 'react'
import { useHistory } from 'react-router'
import sectionFirstOne from 'src/asset/images/sectionone/sectionone.jpg'
import sectionFirstThree from 'src/asset/images/sectionone/sectionthree.jpg'
import sectionFirstTwo from 'src/asset/images/sectionone/sectiontwo.jpg'

import { isMobile } from '../CourseDetail/Practice/Util'
import ImageSlider from './ImageSlider'
import {
  btnBg,
  btnHover,
  fontMain,
  fontSecond,
  hyperTextColor,
  imageFlex,
  imagelg,
  imagemd,
  imageSx,
  textPanelWidth,
} from './util'

const { Box, Flex, Text, Button } = require('@chakra-ui/react')
const selctionOneData = [
  {
    image: sectionFirstThree,
  },
  {
    image: sectionFirstTwo,
  },
  {
    image: sectionFirstOne,
  },
]
const height = '40px'
const width = isMobile ? '180px' : '150px'

const SectionOne = () => {
  const history = useHistory()
  const [autoPlay, setAutoPlay] = useState(false)
  const onSignUp = () => {
    history.push({
      pathname: '/register',
      state: {
        comingFromHome: true,
      },
    })
  }
  return (
    <Flex
      section={'1'}
      bg="#F6F7FB"
      direction={['column', 'column', 'row']}
      w={'100%'}
      px={[5, 5, 5]}
      py={5}
      justifyContent={'center'}
      flex={'1 0 100%'}
    >
      <Box
        textAlign={['center', 'left', 'left']}
        w={['90%', '100%', '30%']}
        flex={imageFlex}
      >
        <ImageSlider
          px={[10, 0, 0]}
          sx={[imageSx, imagemd, imagelg]}
          borderRadius={'15px'}
          slides={selctionOneData}
          centerMode={false}
          autoPlay={autoPlay}
          onClick={() => {
            setAutoPlay(!autoPlay)
          }}
        ></ImageSlider>
      </Box>
      <Box px={[10, 0, 10]} py={[10, 2, 1]} w={textPanelWidth}>
        <Text textAlign={'left'} fontSize={fontMain} fontWeight={900}>
          <Text
            bg={hyperTextColor}
            // color={'#fff'}
            display={'inline'}
            fontWeight={900}
            // px={2}
            fontSize={fontMain}
          >
            {' Save time '}
          </Text>
          and focus on learning
        </Text>
        <Text display={'inline'} fontWeight={900} fontSize={fontMain} py={0}>
          what you really need.
        </Text>
        {!isMobile && (
          <Text
            textAlign={'left'}
            pt={4}
            fontSize={fontSecond}
            fontWeight={600}
          >
            80% of the time, we use only 20% of the words, phrases rules.{' '}
          </Text>
        )}
        {isMobile && (
          <Text
            textAlign={'left'}
            pt={4}
            fontSize={fontSecond}
            fontWeight={600}
          >
            80% of the time, we use only 20% of the words, phrases rules. and
            grammar rules.{' '}
          </Text>
        )}
        {!isMobile && (
          <>
            <Text
              textAlign={'left'}
              pb={4}
              fontSize={fontSecond}
              fontWeight={600}
            >
              and grammar rules.{' '}
              <Text
                bg={hyperTextColor}
                // color={'#fff'}
                display={'inline'}
                fontWeight={900}
                //  px={2}
                fontSize={fontSecond}
              >
                {'Don’t learn everything!'}
              </Text>
            </Text>
          </>
        )}
        {isMobile && (
          <>
            <Text
              bg={hyperTextColor}
              // color={'#fff'}
              display={'inline'}
              fontWeight={900}
              //  px={2}
              fontSize={fontSecond}
            >
              {'Don’t learn everything!'}
            </Text>
          </>
        )}
        <Text
          textAlign={'left'}
          pt={isMobile ? 2 : 0}
          fontSize={fontSecond}
          fontWeight={600}
        >
          Langistan is built on Pareto's principle - 80/20 rule
        </Text>
        <Box pt={[5, 5, 3]} textAlign={['center', 'center', 'inherit']}>
          <Button
            color="#707070"
            w={width}
            h={height}
            //bg={btnBg}
            style={{
              border: '1px solid #707070',
              backgroundColor: '#fff',
            }}
            borderRadius={'5px'}
            _hover={btnHover}
            onClick={onSignUp}
          >
            Sign up Now
          </Button>
        </Box>
      </Box>
    </Flex>
  )
}

export default SectionOne
