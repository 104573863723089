/* eslint-disable no-unused-vars */
import { Box, Flex, HStack, Image, VStack } from '@chakra-ui/react'
import { useState } from 'react'

import ListenAudio from '../HelperComponents/ListenAudio'
import { getFileUrl, isMobile } from '../Util'
import useMediaOptions from './useMediaOptions'

const ReturnItemList = ({ mediaList, selectedMedia, onAnswerClick }) => {
  const mediaAdded = {}
  return mediaList.map((media, i) => {
    if (mediaAdded[media.value]) {
      return <></>
    }
    const returnOjb = (
      <VStack key={media.key + i}>
        <HStack>
          <Box
            onClick={() => onAnswerClick(media)}
            boxShadow={selectedMedia === media ? '2px 5px #2367f0' : ''}
            borderRadius={'10px'}
            cursor={'pointer'}
            border={'2px solid black'}
            className={'col-sm-3 m-b-10'}
          >
            <Image
              boxSize="100%"
              h={['100px', '100px', '200px']}
              w={['100px', '100px', '200px']}
              src={getFileUrl(media.value)}
              alt="Card"
              borderRadius={'10px'}
            />
          </Box>
          {mediaList[i + 1]?.value && (
            <Box
              onClick={() => onAnswerClick(mediaList[i + 1])}
              boxShadow={
                selectedMedia === mediaList[i + 1] ? '2px 5px #2367f0' : ''
              }
              borderRadius={'10px'}
              cursor={'pointer'}
              border={'2px solid black'}
              className={'col-sm-3 m-b-10'}
              key={media.key + i + 'inner'}
            >
              <Image
                boxSize="100%"
                h={['100px', '100px', '200px']}
                w={['100px', '100px', '200px']}
                src={getFileUrl(mediaList[i + 1]?.value)}
                alt="Card"
                borderRadius={'10px'}
              />
            </Box>
          )}
        </HStack>
      </VStack>
    )
    mediaAdded[media.value] = mediaList[i]?.value
    mediaAdded[mediaList[i + 1]?.value] = mediaList[i + 1]?.value
    return returnOjb
  })
}

const ListenWithImage = ({ exercise, setSelectedAnswer }) => {
  const [selectedMedia, setSelectedMedia] = useState({})
  const { mediaList } = useMediaOptions({
    exercise,
  })

  const onAnswerClick = media => {
    setSelectedMedia(media)
    setSelectedAnswer(media)
  }

  return (
    <>
      <VStack gap={[2, 2, 10]}>
        <HStack justifyContent="center">
          <ListenAudio data={exercise} />
        </HStack>
        <Flex
          direction={['column', 'column', 'row']}
          justifyContent="center"
          gap={[5, 5, 10]}
        >
          {isMobile && (
            <ReturnItemList
              mediaList={mediaList}
              selectedMedia={selectedMedia}
              onAnswerClick={onAnswerClick}
            ></ReturnItemList>
          )}
          {!isMobile &&
            mediaList.map((media, i) => {
              return (
                <Box
                  onClick={() => onAnswerClick(media)}
                  boxShadow={selectedMedia === media ? '2px 5px #2367f0' : ''}
                  borderRadius={'10px'}
                  cursor={'pointer'}
                  border={'2px solid black'}
                  className={'col-sm-3 m-b-10'}
                  key={media.key + i}
                >
                  <Image
                    boxSize="100%"
                    h={['100px', '100px', '200px']}
                    w={['100px', '100px', '200px']}
                    src={getFileUrl(media.value)}
                    alt="Card"
                    borderRadius={'10px'}
                  />
                </Box>
              )
            })}
        </Flex>
      </VStack>
    </>
  )
}

export default ListenWithImage
