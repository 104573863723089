import { Box, Center, Image } from '@chakra-ui/react'
import { useRef, useState } from 'react'
// import pauseIcon from 'src/asset/images/pause.svg'
import VideoPlayer from 'src/core-ui/VideoPlayer'

import { getFileUrl, isMobile } from '../Util'

const DEFAULT_SIZE = isMobile ? '180px' : '400px'

const WatchVideo = ({ exercise, onProgressCompleted, ...props }) => {
  const [videoDuration, setVideoDuration] = useState(0)
  const calledOnceRef = useRef(false)

  const onProgressChange = progress => {
    if (calledOnceRef.current) return

    const completedPercentage = Math.round(
      (Math.round(progress.playedSeconds) / videoDuration) * 100
    )
    if (onProgressCompleted && parseInt(completedPercentage) > 85) {
      onProgressCompleted()
      calledOnceRef.current = true
    }
  }

  if (exercise.isImage && exercise.exerciseImage) {
    return (
      <Box
        boxShadow={'2px 5px #2367f0'}
        borderRadius={'10px'}
        cursor={'pointer'}
        className={'col-sm-3 m-b-10'}
      >
        <Image
          boxSize="100%"
          h={DEFAULT_SIZE}
          w={DEFAULT_SIZE}
          src={getFileUrl(exercise.exerciseImage)}
          alt="Card"
          borderRadius={'10px'}
        />
      </Box>
    )
  }

  const videoUrl = exercise?.exerciseVideo
    ? getFileUrl(exercise.exerciseVideo)
    : exercise?.video
    ? exercise.video
    : undefined

  const vidHeight = props.height || DEFAULT_SIZE
  const vidWidth = props.width || DEFAULT_SIZE

  const videoNotAvailable = (
    <Center bg="#1d54c3" w="300px" h="300px" p={4} color="white">
      Video not available
    </Center>
  )
  console.log('videoUrl', videoUrl)

  return (
    <Box
      borderRadius={'25px'}
      overflow="hidden"
      boxShadow={'5px 5px #12357B;'}
      w={'fit-content'}
    >
      {!isMobile ? (
        videoUrl ? (
          <Box width={vidWidth} height={vidHeight}>
            <VideoPlayer
              width={vidWidth}
              height={vidHeight}
              ratio={vidHeight === vidWidth ? 1 : undefined}
              onProgress={progress => {
                onProgressChange(progress)
              }}
              onDuration={duration => setVideoDuration(duration)}
              url={videoUrl}
            />
          </Box>
        ) : (
          videoNotAvailable
        )
      ) : videoUrl ? (
        <Box width="214px" height="214px">
          <VideoPlayer
            ratio="1"
            url={videoUrl}
            onProgress={progress => {
              onProgressChange(progress)
            }}
            onDuration={duration => setVideoDuration(duration)}
            config={{
              youtube: {
                playerVars: {
                  controls: 1,
                  modestbranding: 1,
                },
              },
              vimeo: {
                playerOptions: {
                  controls: 1,
                },
              },
            }}
            width="214px"
            height="214px"
            autoplay
          />
        </Box>
      ) : (
        videoNotAvailable
      )}
    </Box>
  )
}

export default WatchVideo
