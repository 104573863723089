import {
  Box,
  Button,
  Center,
  Divider,
  Flex,
  HStack,
  Image,
  Input,
  Stack,
  Text,
} from '@chakra-ui/react'
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  Elements,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js'
import React, { useMemo, useState } from 'react'
import paypal_logo from 'src/asset/images/payment/paypal_logo.png'
import visa_logo from 'src/asset/images/payment/visa_logo.png'
import API from 'src/util/api'
import getStripe from 'src/util/get-stripejs'

export default function Checkout() {
  return (
    <Elements stripe={getStripe()}>
      <Flex direction={'row'} gap="10" maxWidth={'80%'} mx="auto" mt="20">
        <PaymentForm />
        {/* <PaymentElement /> */}
        <PaymentSummary />
      </Flex>
    </Elements>
  )
}

function PaymentForm() {
  const [selectedPayment, setSelectedPayment] = useState('card')

  const handleInputChange = () => {
    // TO be implemented
  }

  const options = useMemo(
    () => ({
      iconStyle: 'default',
      style: {
        base: {
          iconColor: '#fa755a',
          color: '#000000',
          fontSmoothing: 'antialiased',
          fontSize: '16px',
          '::placeholder': {
            color: '#aab7c4',
          },
        },
        invalid: {
          color: '#fa755a',
          iconColor: '#fa755a',
        },
      },
    }),
    []
  )

  const hanlePaymentMethodClick = method => {
    setSelectedPayment(method)
  }

  return (
    <Box flex={1}>
      <Text fontSize="28px" fontWeight={'semibold'}>
        Payment
      </Text>
      <Text fontWeight={'medium'} fontSize="16px">
        Choose payment menthod below
      </Text>
      <Stack gap="2">
        <HStack>
          <Box
            h="78px"
            w="132px"
            border="1px solid"
            borderColor={selectedPayment === 'card' ? '#2469F5' : '#E7E7E7'}
            borderRadius="5px"
            onClick={() => hanlePaymentMethodClick('card')}
          >
            <Center h="100%">
              <Box>
                <Image src={visa_logo} h="20px" mx="auto" />
                <Text fontSize={'9px'} fontWeight="semibold">
                  Pay with credit/debit Card
                </Text>
              </Box>
            </Center>
          </Box>
          <Box
            h="78px"
            w="132px"
            border="1px solid #2469F5"
            borderRadius="5px"
            borderColor={selectedPayment === 'paypal' ? '#2469F5' : '#E7E7E7'}
            onClick={() => hanlePaymentMethodClick('paypal')}
          >
            <Center h="100%">
              <Box>
                <Image src={paypal_logo} h="20px" mx="auto" />
                <Text fontSize={'9px'} fontWeight="semibold">
                  Pay with paypal
                </Text>
              </Box>
            </Center>
          </Box>
        </HStack>
        <Stack>
          <Input
            placeholder="Name on card"
            type="Text"
            name="cardholderName"
            onChange={handleInputChange}
            borderColor="#707070"
            borderRadius={0}
            required
          />
          <Box border="1px solid #707070" padding="12px 16px">
            <CardNumberElement options={options} showIcon />
          </Box>
          <Flex dir="column" gap="5">
            <Box border="1px solid #707070" padding="12px 16px" flex="1">
              <CardExpiryElement options={options} />
            </Box>
            <Box border="1px solid #707070" padding="12px 16px" flex="1">
              <CardCvcElement options={options} />
            </Box>
          </Flex>
        </Stack>
      </Stack>
    </Box>
  )
}

function PaymentSummary() {
  const stripe = useStripe()
  const elements = useElements()

  const handleSubmit = async event => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault()

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      console.log('stripe or elements is not loaded')
      return
    }

    const { error: backendError, clientSecret } = await API.post(
      '/create-payment-intent',
      {
        paymentMethodType: 'card',
        currency: 'inr',
      }
    )

    const { error: stripeError, paymentIntent } =
      await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: elements.getElement(CardNumberElement),
          billing_details: {
            name: 'Manish Kumar',
            address: {
              city: 'Delhi',
              country: 'IN',
              line1: '1234 Main St',
              line2: 'Apt 1',
              postal_code: '12345',
              state: 'Delhi',
            },
          },
        },
      })

    if (stripeError) {
      // Show error to your customer (e.g., insufficient funds)
      console.log(stripeError)
      // addMessage(stripeError.message)
      return
    }

    // Show a success message to your customer
    // There's a risk of the customer closing the window before callback
    // execution. Set up a webhook or plugin to listen for the
    // payment_intent.succeeded event that handles any business critical
    // post-payment actions.
    // addMessage(`Payment ${paymentIntent.status}: ${paymentIntent.id}`)
    console.log(`Payment ${paymentIntent.status}: ${paymentIntent.id}`)
  }

  return (
    <form onSubmit={handleSubmit}>
      <Stack flex="1">
        <Text fontSize={24} fontWeight="medium">
        Summary
        </Text>
        <HStack>
          <Text>Original Price:</Text>
          <Box flex={1} />
          <Text ml="auto">$89</Text>
        </HStack>
        <Divider />
        <HStack>
          <Text fontWeight="semibold">Total:</Text>
          <Box flex={1} />
          <Text fontWeight="semibold">$89</Text>
        </HStack>
        <Text>Some small text i don't know what to put here</Text>
        <Button width={'100%'} variant="primary" type="submit">
          Pay now
        </Button>
      </Stack>
    </form>
  )
}
