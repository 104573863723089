import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { useUser } from 'src/hooks/useUser'
import { UpdateUserProgress } from 'src/redux/actions'
import { getAdminAppUrl } from 'src/util/getAdminAppUrl'
import { checkAnswer } from 'src/util/helpers'

import useExerciseProgress from './useExerciseProgress'
import usePracticeUtil from './usePracticeUtil'

export const NAV_STATE = {
  NEUTRAL: 'neutral',
  CORRECT: 'correct',
  INCORRECT: 'incorrect',
  NO_LISTENING: 'noListening',
}

export const ACTIVITY_TYPE = {
  Exercise: 'Exercise',
  Vocabulary: 'Vocabulary',
  Feedback: 'Feedback',
}

const usePracticeNav = ({
  selectedAnswer,
  exerciseTodo,
  setSelectedAnswer,
  exerciseCount,
  setExerciseCount,
  vocabularyCount,
  vocabularyToDo,
  setVocabularyCount,
  setActivityScreen,
}) => {
  const dispatch = useDispatch()
  const params = useParams()
  const { moveToNextLesson } = usePracticeUtil()
  const [isSelectedAnswerCorrect, setIsSelectedAnswerCorrect] = useState(null)

  const [activeScreen, setActiveScreen] = useState(ACTIVITY_TYPE.Exercise)

  const { lessonExercises, lessonExercisesProgress, lessonVocabularies } =
    useSelector(store => store.mainReducer)
  const { isAdmin } = useUser()

  const {
    exercisesProgressData: {
      isExerciseCompleted,
      isPronounceCompleted,
      isFeedbackCompleted,
    },
  } = useExerciseProgress(
    lessonExercises,
    lessonExercisesProgress,
    lessonVocabularies
  )

  useEffect(() => {
    setIsSelectedAnswerCorrect(null)
  }, [selectedAnswer])

  useEffect(() => {
    if (isExerciseCompleted && !isFeedbackCompleted) {
      setActiveScreen(ACTIVITY_TYPE.Feedback)
      setActivityScreen(ACTIVITY_TYPE.Feedback)
    }
  }, [isFeedbackCompleted, isExerciseCompleted])

  const handleSkip = () => {
    const nextCount =
      exerciseCount < exerciseTodo.length - 1 ? exerciseCount + 1 : 0
    setSelectedAnswer(null)
    setExerciseCount(nextCount)
  }

  // const handleRemindSkip = () => {
  //   const nextCount =
  //     vocabularyCount < vocabularyToDo.length - 1 ? vocabularyCount + 1 : 0
  //   setSelectedAnswer(null)
  //   setVocabularyCount(nextCount)
  // }

  const isCorrectAnswer = () => {
    return (
      checkAnswer(selectedAnswer, exerciseTodo[exerciseCount]) ||
      selectedAnswer === true
    )
  }

  const handleCheck = () => {
    const correctAnswer = isCorrectAnswer()
    setIsSelectedAnswerCorrect(correctAnswer)
  }

  const handleContinue = skip => {
    setSelectedAnswer(null)

    if (exerciseTodo[exerciseCount]?.id && !isAdmin && !skip) {
      dispatch(
        UpdateUserProgress({
          course: params.courseId,
          unit: params.unitId,
          chapter: params.chapterId,
          lesson: params.lessonId,
          completedExerciseId: exerciseTodo[exerciseCount].id,
        })
      )
      return
    }

    setExerciseCount(exerciseCount + 1)
  }

  const onEditExerciseClick = () => {
    const exercise = exerciseTodo[exerciseCount]
    if (exercise) {
      window.open(
        getAdminAppUrl() + `app/exerciseDetails?_id=${exercise.id}`,
        '_blank'
      )
    }
  }
  const onEditMaterExerciseClick = () => {
    const exercise = exerciseTodo[exerciseCount]
    if (exercise) {
      window.open(
        getAdminAppUrl() +
          `app/exerciseMasters?_id=${exercise.exerciseMasterId}`,
        '_blank'
      )
    }
  }
  // const onEditVocabularyClick = () => {
  //   const vocabulary = vocabularyToDo[vocabularyCount]
  //   if (vocabulary) {
  //     window.open(
  //       getAdminAppUrl() + `app/entertainmentVocabularies?_id=${vocabulary.id}`,
  //       '_blank'
  //     )
  //   }
  // }

  const handleMoveOnNextPage = skip => {
    const isActivityRemaining =
      !isExerciseCompleted || !isPronounceCompleted || !isFeedbackCompleted

    if (isActivityRemaining) {
      moveToNextActivityInCurrentLesson(skip)
      return
    }

    // else move to next lesson
    moveToNextLesson()
  }

  /**
   * next activity in current lesson
   */
  function moveToNextActivityInCurrentLesson(skip) {
    // move to exercise if remaining
    if (exerciseCount < exerciseTodo.length - 1) {
      handleContinue(skip)
      return
    }
    // move to pronounce if remaining
    const isLastExercise = exerciseCount >= exerciseTodo.length - 1
    // if (!isPronounceCompleted && isLastExercise) {
    //   handleVocabulary(skip)
    //   return
    // }

    if (isLastExercise && !isFeedbackCompleted) {
      setActivityScreen(ACTIVITY_TYPE.Feedback)
      setActiveScreen(ACTIVITY_TYPE.Feedback)
      return
    }

    moveToNextLesson()
  }

  return {
    handleSkip,
    handleCheck,
    onEditExerciseClick,
    handleMoveOnNextPage,
    onEditMaterExerciseClick,
    isCorrectAnswer,
    isSelectedAnswerCorrect,
    activeScreen,
  }
}

export default usePracticeNav
