/* eslint-disable no-unused-vars */
import { Box, Flex } from '@chakra-ui/react'
import noop from 'lodash/noop'
import { useState } from 'react'

import RecordAudio from '../HelperComponents/RecordAudio'
import WatchVideo from '../HelperComponents/WatchVideo'
import { isMobile } from '../Util'

const WatchWithRecord = ({ exercise, setSelectedAnswer }) => {
  const youtubeWatermarkOverlayStyles = {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    zIndex: 1,
    cursor: 'pointer',
  }
  const youtubeLinkOverlayStyles = {
    bottom: '70%',
    cursor: 'initial',
  }
  const youtubeWatermarkBottomOverlayStyles = {
    position: 'absolute',
    top: '86%',
    left: 0,
    bottom: 0,
    right: 0,
    zIndex: 1,
    cursor: 'pointer',
  }
  const youtubeLinkBottomOverlayStyles = {
    bottom: '0%',
    left: '0%',
    right: '0%',
    cursor: 'initial',
  }
  return (
    <Flex gap={4} direction={['column', 'column', 'row']} alignItems={'center'}>
      <>
        {!isMobile && (
          <Box
            {...youtubeWatermarkOverlayStyles}
            {...youtubeLinkOverlayStyles}
          ></Box>
        )}
        <WatchVideo exercise={exercise} />
        {!isMobile && (
          <Box
            {...youtubeWatermarkBottomOverlayStyles}
            {...youtubeLinkBottomOverlayStyles}
          />
        )}
      </>
      <RecordAudio
        key={exercise.id}
        setSelectedAnswer={setSelectedAnswer}
        onStopRecording={noop}
      />
    </Flex>
  )
}

export default WatchWithRecord
