import { configureStore } from '@reduxjs/toolkit'

import reducer from './reducer'

export const setupStore = preloadedState => {
  return configureStore({
    reducer,
    preloadedState,
  })
}
