import _ from 'lodash'
import { featureFlags } from 'src/constants/feature-flags'
import api from 'src/util/api'
import create from 'zustand'

const value = _.once(() => {
  const flags = Object.keys(featureFlags).reduce((acc, key) => {
    acc[key] = false
    return acc
  }, {})
  return {
    flags,
    flagList: Object.keys(featureFlags).map(key => ({
      name: key,
      enabled: false,
      siteLevel: false,
    })),
  }
})()

const useFeatureFlagsStore = create((set, get) => ({
  flags: undefined,
  flagList: value.flagList,
  isLoading: false,

  actions: {
    getFlags: async () => {
      set(() => ({ isLoading: true }))
      try {
        const flags = await api.get('/flags')

        get().actions.setFlags(flags)
        set(() => ({ isLoading: false }))
      } catch (error) {
        console.log(error)
        set(() => ({ isLoading: false, flags: null }))
      }
    },

    setFlags: flags =>
      set(state => {
        const updatedFlagList = _.chain(state.flagList)
          .map(flag => {
            const flagObj = flags.find(f => f.name === flag.name)
            return { ...flag, ...flagObj }
          })
          .value()

        const flagsObject = updatedFlagList.reduce((acc, flag) => {
          acc[flag.name] = flag.enabled || flag.siteLevel
          return acc
        }, {})

        return { flags: flagsObject, flagList: updatedFlagList }
      }),
  },
}))

export const useFlags = () => useFeatureFlagsStore(state => state.flags)
export const useFlagList = () => useFeatureFlagsStore(state => state.flagList)
export const useIsFlagLoading = () =>
  useFeatureFlagsStore(state => state.flags === undefined)

export const useFeatureFlagAction = () =>
  useFeatureFlagsStore(state => state.actions)
