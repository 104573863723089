import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import App from 'src/App'
import Layout from 'src/layout'
import { getCourses } from 'src/redux/actions'
import {
  useFeatureFlagAction,
  useIsFlagLoading
} from 'src/redux/useFeatureFlags'

export const AuthenticatedRoutes = () => {
  const { getFlags } = useFeatureFlagAction()
  const isFlagLoading = useIsFlagLoading()
  const dispatch = useDispatch()

  useEffect(() => {
    getFlags()
    dispatch(getCourses())
  }, [])

  if (isFlagLoading) return null

  return (
    <Layout>
      <App />
    </Layout>
  )
}
