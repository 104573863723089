import { useState } from 'react'
import { useHistory } from 'react-router'
import five from 'src/asset/images/banner/five.jpg'
import four from 'src/asset/images/banner/four.jpg'
import one from 'src/asset/images/banner/one.jpg'
import three from 'src/asset/images/banner/three.jpg'
import two from 'src/asset/images/banner/two.jpg'

import { isMobile } from '../CourseDetail/Practice/Util'
import ImageSlider from './ImageSlider'
import {
  bannerText,
  fontMain,
  fontSecond,
  imagelg,
  imagemd,
  imageSx,
} from './util'

const { Box, Flex, Text, HStack, VStack, Button } = require('@chakra-ui/react')
const selctionOneData = [
  {
    image: one,
  },
  {
    image: two,
  },
  {
    image: three,
  },
  {
    image: four,
  },
  {
    image: five,
  },
]

const Banner = () => {
  const [activeIndex, setActiveIndex] = useState(0)
  const history = useHistory()
  const onLogin = () => {
    history.push({
      pathname: '/login',
      state: {
        comingFromHome: true,
      },
    })
  }
  return (
    <Flex
      direction={['column', 'column', 'row']}
      w={'100%'}
      px={[0]}
      py={[0, 0, 0]}
      style={{
        marginTop: 0,
      }}
    >
      <VStack textAlign={['center', 'left', 'left']} w={['100%']} h={['100%']}>
        <ImageSlider
          px={[0]}
          sx={[imageSx, imagemd, imagelg]}
          //borderRadius={'15px'}
          slides={selctionOneData}
          centerMode={false}
          autoPlay={true}
          onChange={index => {
            setActiveIndex(index)
          }}
        ></ImageSlider>

        <HStack
          px={[10, 3, 5]}
          py={[10, 2, 1]}
          pos={'absolute'}
          w={['70%', '60%', '45%']}
          left={['18px', '-5px', '15px']}
          top={['3%']}
          flexDirection={'column'}
          alignItems={'flex-start'}
        >
          <Box maxH={'200px'} minH={'200px'} h={'200'}>
            <Text
              textAlign={'left'}
              fontSize={isMobile ? '16px' : fontMain}
              fontWeight={900}
              color={isMobile ? '#2d2d2d' : '#2d2d2d'}
            >
              {bannerText[activeIndex]['main']}
            </Text>
            <Text
              textAlign={'left'}
              pt={4}
              fontSize={isMobile ? '14px' : fontSecond}
              fontWeight={400}
              style={{
                marginInlineStart: 0,
              }}
              color={isMobile ? '#2d2d2d' : '#2d2d2d'}
            >
              {bannerText[activeIndex]['nextText']}
            </Text>
          </Box>
          {!isMobile && (
            <Box alignItems={'center'} py={0} h={'150px'} px={10}>
              <Button
                color="#fff"
                w={'220px'}
                h={'50px'}
                fontSize={'20px'}
                bg={'brand.blue'}
                _hover={{
                  bg: 'brand.blue',
                }}
                borderRadius={5}
                onClick={onLogin}
              >
                {bannerText[activeIndex]['buttonText']}
              </Button>
            </Box>
          )}
        </HStack>
      </VStack>
    </Flex>
  )
}

export default Banner
