import { AspectRatio, Box, Image, Text } from '@chakra-ui/react'
import { useRef, useState } from 'react'
import ReactPlayer from 'react-player'
import playIcon from 'src/asset/images/play.svg'
import { isMobile } from 'src/component/CourseDetail/Practice/Util'

const overlayConfig = {
  position: 'absolute',
  width: '100%',
  height: '100%',
  left: '0',
  top: 0,
  right: '0',
  display: 'flex',
}

export default function VideoPlayer({ url, ref, ratio, autoplay, ...props }) {
  const playerRef = useRef()
  const [playing, setPlaying] = useState(autoplay ?? false)

  if (!url) {
    return <Text>Url is empty</Text>
  }

  const maxWidth =
    ratio === 1 && props.width
      ? props.width
      : { sm: '100%', md: 640, lg: 720, xl: 860, '2xl': 1280 }

  return (
    <AspectRatio ratio={ratio ?? 16 / 9} maxWidth={maxWidth} mx="auto">
      <Box position="relative" width="100%">
        <ReactPlayer
          url={url}
          playing={playing}
          controls={true}
          onPlay={() => setPlaying(true)}
          onPause={() => setPlaying(false)}
          onEnded={() => {
            if (playerRef.current) {
              setPlaying(false)
              playerRef.current.seekTo(0)
            }
            props.onEnded?.()
          }}
          ref={ref ?? playerRef}
          {...props}
        />
        {!playing && (
          <Box {...overlayConfig} onClick={() => setPlaying(true)}>
            <Image src={playIcon} margin="auto" />
          </Box>
        )}
        {playing && !isMobile && (
          <YouTubeOverlay onClick={() => setPlaying(false)} ratio={ratio} />
        )}
      </Box>
    </AspectRatio>
  )
}

function YouTubeOverlay({ onClick, ratio }) {
  return (
    <>
      <Box
        position={'absolute'}
        height={ratio === 1 ? '100%' : '80%'}
        top="0"
        right="0"
        left="0"
        zIndex={1}
        onClick={onClick}
      />
    </>
  )
}
