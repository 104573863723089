import { HStack, Image, Text, VStack, Box } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import cursor_hand from 'src/asset/images/cursorhand.svg'
import VideoPlayer from 'src/core-ui/VideoPlayer'

function PronounceTab({ lessonVocabularies }) {
  const [showAnswer, setShowAnswer] = useState(false)
  useEffect(() => {
    return () => {
      setShowAnswer(false)
    }
  }, [lessonVocabularies])

  return (
    <VStack gap={2}>
      {lessonVocabularies ? (
        <>
          <HStack
            justifyContent={'center'}
            border="0.5px solid #707070"
            minWidth={340}
            minHeight={50}
            display={'flex'}
            flexDirection={'column'}
            fontWeight={'semibold'}
            p={3}
          >
            <Text fontSize="xl">{lessonVocabularies.wordMeaningToDisplay}</Text>
            <Text fontSize="md">{lessonVocabularies.wordInRoman}</Text>
          </HStack>
          <Box width="300px" height="300px">
            <VideoPlayer
              url={lessonVocabularies.video}
              width="300px"
              height="300px"
              ratio={1}
              style={{ borderRadius: '10px' }}
            />
          </Box>
          <HStack
            border="1px solid #2367f0"
            borderRadius="5"
            minWidth={300}
            justifyContent="center"
            minHeight={50}
            cursor="pointer"
            p={3}
            onClick={() => setShowAnswer(true)}
            bg={!showAnswer ? 'darkBlue' : ''}
          >
            {!showAnswer && (
              <Text color={'#fff'} display={'inline-flex'}>
                Discover Meaning
                <Image
                  src={cursor_hand}
                  style={{ marginLeft: '15px' }}
                  alt="hand"
                />
              </Text>
            )}

            {showAnswer && <Text>{lessonVocabularies.wordInNative}</Text>}
          </HStack>
        </>
      ) : null}
    </VStack>
  )
}

export default PronounceTab
