import {  Button, Flex, Image, Text } from '@chakra-ui/react'
import React from 'react'
import { useHistory } from 'react-router'
import unsubscribed from 'src/asset/images/unsubscribed.svg'

const Unsubscribed = () => {
  const history = useHistory()
  return (
    <Flex direction={"column"} gap={2} pt={"10%"} justifyContent="center" alignItems={"center"} w="100%" margin="auto">
      <Image m="auto" src={unsubscribed} w={114} h={50}></Image>
      <Text color={'#2469F5'}>Oops !</Text>
      <Text fontSize={'14px'}>
        Seems, like you’ve not taken our membership yet.<br></br> Please
        subscribe for unlimited access.
      </Text>
      <Button
        colorScheme="blue"
        size="lg"
        fontSize={'14px'}
        onClick={() => history.push('/pricing')}
      >
        Subscribe Now
      </Button>
    </Flex>
  )
}

export default Unsubscribed
