import { Textarea } from '@chakra-ui/react'

const RightAnswer = ({ setSelectedAnswer, selectedAnswer }) => (
  <Textarea
    placeholder="Type your answer here..."
    name="typeAns"
    w={['300px', '300px', '75%']}
    border="1px solid #707070"
    opacity="1"
    resize={'none'}
    borderRadius="0"
    value={selectedAnswer || ''}
    onChange={({ target: { value } }) => {
      setSelectedAnswer(value.length ? value?.trim() : null)
    }}
  />
)

export default RightAnswer
