import { Center, Flex, Image, Text, VStack } from '@chakra-ui/react'
import Lottie from 'lottie-react'
import { useRef, useState } from 'react'
import audio from 'src/asset/images/audio.png'
import waveData from 'src/asset/lottie-json/audio_wave.json'

import WatchVideo from '../HelperComponents/WatchVideo'
import { isMobile } from '../Util'
import useMediaOptions from './useMediaOptions'

const WatchWithAudio = ({ exercise, setSelectedAnswer }) => {
  const [selectedMedia, setSelectedMedia] = useState('')
  const lottieRef = useRef()
  const { mediaList, onAudioPlay } = useMediaOptions({
    exercise,
    hasAudio: true,
    onAudioStop: () => {
      lottieRef.current?.stop()
    },
  })

  const onAnswerClick = media => {
    setSelectedMedia(media.key)
    onAudioPlay(media.value)
    setSelectedAnswer(media)
  }

  return (
    <Flex
      alignItems="center"
      direction={['column', 'column', 'row']}
      gap={[5, 5, 10]}
    >
      <WatchVideo exercise={exercise} />
      <VStack gap={[1, 1, 3]} justifyContent="center">
        {mediaList.map((media, i) => (
          <Center
            key={media.key}
            variant="plain"
            onClick={() => {
              onAnswerClick(media)
            }}
            border="1px solid #707070"
            boxShadow={media.key === selectedMedia ? '2px 5px #2367f0' : ''}
            borderRadius="25px"
            w="200px"
            h="70px"
            cursor="pointer"
            overflow="hidden"
          >
            {selectedMedia === media.key ? (
              <Lottie
                animationData={waveData}
                loop={true}
                lottieRef={lottieRef}
              />
            ) : (
              <>
                <Image mr={3} boxSize={'20px'} src={audio} />{' '}
                <Text color={'#000000'} fontSize="xs">
                  Audio {i + 1}
                </Text>
              </>
            )}
          </Center>
        ))}
      </VStack>
    </Flex>
  )
}

export default WatchWithAudio
