import { VStack } from '@chakra-ui/react'

import ListenAudio from '../HelperComponents/ListenAudio'
import RightAnswer from '../HelperComponents/RightAnswer'

const ListenWithType = ({ exercise, ...props }) => {
  return (
    <VStack textAlign="center" gap={[5, 5, 10]}>
      <ListenAudio data={exercise} />
      <RightAnswer {...props} />
    </VStack>
  )
}

export default ListenWithType
