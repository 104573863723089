import { tokenStore, useTokenStore } from 'src/redux/useTokenStore'
import API from 'src/util/api'
import useSWRImmutable from 'swr/immutable'

const fetcher = url => API.post(url)

export function useToken() {
  const { token } = useTokenStore()

  const { data, error } = useSWRImmutable(
    token ? null : 'refreshToken',
    fetcher,
    {
      onSuccess: data => {
        if (data?.success) tokenStore.setState({ token: data.token })
        return null
      },
    }
  )
  return {
    isLoading: !data && !error && !token,
    error,
    token: data?.token || token,
  }
}
