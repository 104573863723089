import { ArrowForwardIcon, EditIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Image,
  Spinner,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import flagIcon from 'src/asset/images/flag.svg'
import VideoPlayer from 'src/core-ui/VideoPlayer'
import useSubscription from 'src/hooks/useSubscription'
import { useUser } from 'src/hooks/useUser'
import {
  GetLessonVideo,
  SetLessonExercises,
  SetLessonVideo,
  SetLessonVocabularies,
  UpdateUserProgress,
} from 'src/redux/actions'
import { getAdminAppUrl } from 'src/util/getAdminAppUrl'

import MediaSharePanel from '../MediaSharePanel'
import ReportSection from '../ReportSection'
import NoRecordFound from './NoRecordFound'
import { isMobile } from './Practice/Util'
import Unsubscribed from './Unsubscribed'
// const btnStyle = { height: '48px', marginRight: '10px' }

const ShareReportOptions = ({ setOpen }) => {
  return (
    <VStack style={{ position: 'fixed', top: '110px', marginRight: '10px' }}>
      <Image
        src={flagIcon}
        alt="Report Issue Langistan"
        boxSize="25px"
        onClick={setOpen}
        cursor={'pointer'}
      ></Image>

      <MediaSharePanel showText={false}></MediaSharePanel>
    </VStack>
  )
}

const LearnTab = ({ onLearnVideoEnd }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const params = useParams()
  const { isSubscriptionActive } = useSubscription(params.courseId)
  const { loading: userLoading, user: auth } = useUser()

  const {
    lessonVideo,
    selectedLesson,
    courseLeftMenu,
    loading,
    loadingCourse,
  } = useSelector(store => store.mainReducer)

  const [open, setOpen] = useState(false)
  const [videoDuration, setVideoDuration] = useState(0)

  useEffect(() => {
    return () => {
      dispatch(SetLessonVocabularies([])) &&
        dispatch(SetLessonExercises([])) &&
        dispatch(SetLessonVideo({}))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (selectedLesson?.lesson?.videos?.[0]) {
      dispatch(GetLessonVideo(selectedLesson.lesson.videos[0]))
    }
    return () => {
      dispatch(SetLessonVideo({}))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLesson])

  const onProgressCompleted = () => {
    let exerciseCompletedData = {
      course: selectedLesson?.extraInfo?.courseId,
      unit: selectedLesson?.extraInfo.unitId,
      chapter: selectedLesson.chapterId,
      lesson: selectedLesson.lesson?.id,
      videoCompleted: true,
    }
    dispatch(UpdateUserProgress(exerciseCompletedData))
  }

  if (
    !(
      selectedLesson &&
      selectedLesson.lesson &&
      selectedLesson.lesson.videos
    ) &&
    !loading &&
    !loadingCourse
  ) {
    return <NoRecordFound textToDisplay="video" />
  }

  const onPracticeClick = () => {
    const lessonData = selectedLesson
    if (lessonData.lesson.linkedExerciseIds.length === 0) {
      onContinueClick()
    } else {
      let url = `${history.location.pathname}/practice`
      url = url.replace('/introduction', '')
      history.push(url)
    }
  }

  const onEditLessonClick = () => {
    if (lessonVideo) {
      window.open(
        getAdminAppUrl() + `app/lessons?_id=${selectedLesson.lesson?.id}`,
        '_blank'
      )
    }
  }

  const getNextLesson = () => {
    if (selectedLesson.nextLesson) return selectedLesson.nextLesson

    const currentChapter = courseLeftMenu.chapters.find(
      item => item.chapterId === selectedLesson.chapterId
    )

    if (currentChapter) {
      const currentLessonIndex = currentChapter.lessons.findIndex(
        lesson => lesson.id === selectedLesson.lesson.id
      )

      if (currentLessonIndex > -1) {
        return currentChapter.lessons[currentLessonIndex + 1]
      }
    }

    return
  }

  const onContinueClick = () => {
    const currentLesson = selectedLesson
    const nextLesson = getNextLesson()
    if (nextLesson) {
      const url = `/introduction/course/${params.courseId}/chapter/${params.chapterId}/unit/${params.unitId}/lesson/${nextLesson.id}`
      history.push(url)
    } else {
      // checking for next chapter
      const currentChapterIndex = courseLeftMenu.chapters.findIndex(
        item => item.chapterId === currentLesson.chapterId
      )
      if (currentChapterIndex !== -1) {
        let nextChapter = courseLeftMenu.chapters[currentChapterIndex + 1]
        if (nextChapter && nextChapter.lessons) {
          const lesson = nextChapter.lessons[0] //moving the first item of next chapter
          const url = `/introduction/course/${params.courseId}/chapter/${params.chapterId}/unit/${params.unitId}/lesson/${lesson.id}`
          history.push(url)
        } else {
          history.push('/language')
        }
      } else {
        history.push('/language')
      }
    }
  }

  const onProgressChange = progress => {
    let completedPercentage = Math.round(
      (Math.round(progress.playedSeconds) / videoDuration) * 100
    )
    if (
      parseInt(completedPercentage) > 85 &&
      parseInt(completedPercentage) < 86 &&
      onProgressCompleted
    ) {
      onProgressCompleted()
    }
  }

  // if (loading || userLoading) {
  //   return <Text>Loading</Text>
  // }
  if (
    !loading &&
    !selectedLesson?.lesson?.isUnLock &&
    !isSubscriptionActive &&
    auth.role !== 'ADMIN' &&
    !auth.isStarUser
  ) {
    return <Unsubscribed />
  }

  return (
    <>
      <Stack width={'full'}>
        {/* <NavLink to={`/coursedetails/${unitId}`}>{'< Back to courses'}</NavLink> */}
        {/* {Object.keys(lessonVideo).length === 0 && (
          <Spinner
            size="xl"
            position="absolute"
            top="50%"
            left="50%"
            bottom="50%"
          />
        )} */}
        {isMobile && (
          <Heading size={'sm'} mt="35px" textAlign={'center'}>
            {selectedLesson?.lesson?.name}
          </Heading>
        )}
        {Object.keys(lessonVideo).length !== 0 && (
          <Box paddingTop={['20px', '20px', '50px']}>
            <VideoPlayer
              url={lessonVideo.video}
              onProgress={progress => {
                onProgressChange(progress)
              }}
              onDuration={duration => setVideoDuration(duration)}
              config={{
                youtube: {
                  playerVars: {
                    controls: 1,
                    modestbranding: 1,
                  },
                },
                vimeo: {
                  playerOptions: {
                    controls: 1,
                  },
                },
              }}
              width="100%"
              height="100%"
              autoplay
            />
            <VStack alignItems={'flex-end'}>
              {!isMobile && <ShareReportOptions setOpen={setOpen} />}
              {isMobile && (
                <VStack>
                  <Box p={10} w={'full'}>
                    <Flex
                      justifyContent={'flex-end'}
                      alignItems={'center'}
                      w={'100%'}
                    >
                      <Image
                        src={flagIcon}
                        alt="Report Issue Langistan"
                        boxSize="25px"
                        onClick={setOpen}
                        cursor={'pointer'}
                        mr={'30px'}
                      ></Image>

                      <MediaSharePanel showText={false}></MediaSharePanel>
                    </Flex>
                  </Box>
                </VStack>
              )}
              <HStack
                padding="20px"
                h={'70px'}
                bgColor="#F2F7FF"
                position="Fixed"
                bottom="0"
                width="full"
                justifyContent="center"
              >
                <HStack width="90%" justifyContent="flex-end">
                  <Button
                    rightIcon={<ArrowForwardIcon />}
                    onClick={onPracticeClick}
                    variant="primary"
                    size={'lg'}
                    width={isMobile && auth?.role !== 'ADMIN' ? '100%' : 'auto'}
                    // style={btnStyle}
                  >
                    Continue
                  </Button>
                  {auth?.role === 'ADMIN' && (
                    <Button
                      onClick={onEditLessonClick}
                      rightIcon={<EditIcon />}
                      variant="primary"
                      size={'lg'}
                      // style={btnStyle}
                    >
                      Edit
                    </Button>
                  )}
                </HStack>
              </HStack>
            </VStack>
          </Box>
        )}
        {open && <ReportSection open={open} setOpen={setOpen}></ReportSection>}
      </Stack>
    </>
  )
}

export default React.memo(LearnTab)
