import { Route, Switch } from 'react-router'
import HomePage from 'src/component/homePage/Homepage'
import LoginSignupTabs from 'src/component/LoginSignupTabs'
import ResetPassword from 'src/component/ResetPassword'

export const UnauthenticatedRoutes = () => (
  <Switch>
    <Route path="/login" exact={true} component={LoginSignupTabs} />
    <Route
      path="/login/change-password/:resetTokenPassword"
      exact={true}
      component={ResetPassword}
    />
    <Route path="/register" exact={true} component={LoginSignupTabs} />
    <Route path="/home" exact={true} component={HomePage}></Route>
    <Route path="/" exact={true} component={HomePage} />
  </Switch>
)
