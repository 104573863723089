import { Tag as ChakraTag, Text } from '@chakra-ui/react'

const TagOption = ({
  option = {},
  index,
  onClick,
  disabled,
  separator,
  ...props
}) => (
  <ChakraTag
    flexDirection="column"
    padding={[2, 2, 6]}
    cursor={disabled ? 'not-allowed' : 'pointer'}
    background="#FFFFFF"
    borderRadius={10}
    opacity={1}
    margin={[1, 1, 2]}
    onClick={() => onClick(option, index)}
    key={option.value}
    gap={2}
    title="Click here to put in order"
    {...props}
  >
    <>
      {option.nativeValue && (
        <Text fontSize={['16px', '16px', '24px']} fontWeight="semibold">
          {option.nativeValue}
        </Text>
      )}
      {separator || null}
      <Text fontSize={['14px', '14px', '20px']}>{option.value}</Text>
    </>
  </ChakraTag>
)
export const EmptyTag = props => (
  <ChakraTag padding={24.5} borderRadius={0} margin={2} {...props} />
)
export default TagOption
