import { Flex } from '@chakra-ui/react'
import { useCallback, useState } from 'react'

import WatchVideo from '../HelperComponents/WatchVideo'
import TagOption from './TagOption'
import useMediaOptions from './useMediaOptions'

const WatchWithText = ({ exercise, setSelectedAnswer, disabled }) => {
  const [selectedOption, setSelectedOption] = useState({})
  const { mediaList } = useMediaOptions({ exercise })

  const onAnswerClick = useCallback(
    option => {
      setSelectedOption(option)
      setSelectedAnswer(option)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [exercise]
  )

  return (
    <Flex
      gap={10}
      alignItems="center"
      direction={['column', 'column', 'row']}
    >
      <WatchVideo exercise={exercise} />
      <Flex direction={'column'} gap={[1, 1, 3]}>
        {mediaList.map(
          (option, i) =>
            option.value && (
              <TagOption
                option={option}
                onClick={() => onAnswerClick(option)}
                border="solid #707070 1px"
                borderRadius={10}
                key={option.key + i}
                background="transparent"
                boxShadow={option === selectedOption ? '2px 5px #2367f0' : ''}
                width={['350px']}
              />
            )
        )}
      </Flex>
      {/* <RightAnswer setSelectedAnswer={setSelectedAnswer} /> */}
    </Flex>
  )
}

export default WatchWithText
