import { Button, Input, Text } from '@chakra-ui/react'
import * as $ from 'jquery'
import moment from 'moment'
import React from 'react'
import { connect } from 'react-redux'
import { Link, Redirect } from 'react-router-dom'
import logo from 'src/asset/images/logoBeta.svg'

import * as myConstClass from './constants'
const api_Url = myConstClass.api

class ResetPassword extends React.Component {
  constructor() {
    super()

    this.state = {
      selected: 'password',
      staticImg: true,
      file: null,
      country: '',
      date: '',
      token: '',
      name: '',
      surname: '',
      email: '',
      dob: '',
      profile_pic: '',
      country_id: '',
      register_date: '',
      type: '',
      displaytext: 'hide_block',
      nameErrorMessage: '',
      surnameErrorMessage: '',
      emailErrorMessage: '',
      passwordErrorMessage: '',
      birthdayErrorMessage: '',
      countryErrorMessage: '',
      c_passwordErrorMessage: '',
      imgErrorMessage: '',
      isProfileUpdated: false,
      old_password: '',
      new_password: '',
      c_password: '',
      encrypteddata: '',
      showError: false,
      errorMessage: '',
    }
  }

  datehandleChange = date => {
    this.setState({
      date: date,
      dob: moment(date).format('DD-MM-YYYY'),
    })
  }

  componentWillMount() {
    this.dataSource()

    //     var token  = window.sessionStorage.getItem('token');
    //     this.setState({
    //         token: token
    //     })

    // 	if(token == null) {

    // 	return	this.props.history.push('/');

    // 	}
  }

  //get encrypteddata from parms
  dataSource(props) {
    var encrypteddata = this.props.match.params.resetTokenPassword
    this.setState({ encrypteddata: encrypteddata })
  }

  componentDidMount() {
    $(document).ready(function () {
      var opcionesnav = $('.navoption').length
      var clickhamb = 0

      $('#hamburger').click(function () {
        clickhamb = 1
        var header = $('#myTopnav')
        if (header[0].classList.length === 1) {
          header.addClass('responsive')
          $('header').height((opcionesnav + 1) * 70)
          $('.navlist a:not(.icon)').css('display', 'block')
          setTimeout(function () {
            $('.navlist a:not(.icon)').css('transform', 'translateX(0px)')
          }, 50)
        } else {
          $('.navlist a:not(.icon)').css('transform', 'translateX(600px)')
          header.height(70)
          setTimeout(function () {
            header.removeClass('responsive')
            header.height(70)
            $('.navlist a:not(.icon)').css('display', 'none')
          }, 50)
        }
      })

      $(window).on('resize', function () {
        if ($(window).width() > 600 && clickhamb === 1) {
          $('#myTopnav').height(70)
          $('.navlist a:not(.icon)').css('display', 'block')
          setTimeout(function () {
            $('.navlist a:not(.icon)').css('transform', 'translateX(0px)')
          }, 50)
        }
      })
    })
  }

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
      showError: false,
      errorMessage: '',
    })
  }

  // Forgot Password
  forgotPassword = e => {
    e.preventDefault()
    if (this.state.new_password.trim().length === 0) {
      this.setState({
        showError: true,
        errorMessage: 'Please enter password',
      })
    } else if (this.state.new_password.trim().length <= 5) {
      this.setState({
        showError: true,
        errorMessage: 'Password length should be greater than 5',
      })
    } else if (this.state.c_password.trim().length === 0) {
      this.setState({
        showError: true,
        errorMessage: 'Please enter confirm password',
      })
    } else if (this.state.new_password !== this.state.c_password) {
      this.setState({ displaytext: 'show_block' })
      this.setState({
        showError: true,
        errorMessage: 'Both password does not match',
      })
      this.setState({})
      return
    } else {
      var data = {
        password: this.state.new_password,
        c_password: this.state.c_password,
      }

      fetch(api_Url + `/changePassword/${this.state.encrypteddata}`, {
        method: 'PUT',
        body: JSON.stringify(data),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
          return response.json()
        })
        .then(response => {
          if (response.code === 200) {
            this.setState({
              c_passwordErrorMessage: response.message,
            })
            window.sessionStorage.setItem('isforgot', 'yes')
            setTimeout(this.setState({ isRedirect: true }), 2000)
          } else {
            this.setState({
              showError: true,
              errorMessage: response.message,
            })
          }
        })
    }
  }

  render() {
    if (this.state.isRedirect) {
      return <Redirect to="/login" />
    }
    return (
      <div>
        {!this.props.auth && (
          <header className="topnav" id="myTopnav">
            <Link to="/">
              <img width={'150px'} height={'80px'} padding={'3px'} src={logo} />
            </Link>
          </header>
        )}
        <section
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '3%',
          }}
        >
          <div
            className="container"
            style={{
              boxShadow: '3px 3px 8px 0px',
              border: '1px solid #d8d8d8',
              borderRadius: '10px',
            }}
          >
            <div
              style={{
                textAlign: 'center',
                padding: '12px 0px',
                fontWeight: '600',
              }}
            >
              Set new password
            </div>
            <div style={{ margin: '0 auto', padding: '12px 5px' }}>
              <Text fontSize={'14px'}>
                Your new password must be different to previously used
                passwords.
              </Text>
            </div>

            <div
              className="row"
              style={{
                padding: '20px',
              }}
            >
              {this.state.selected === 'password' && (
                <div className="col-lg-8 m-b-20">
                  <div className="profile-right-side">
                    <form
                      onSubmit={this.forgotPassword}
                      className="login100-form validate-form flex-sb flex-w m-t-0"
                    >
                      {/* <div className="m-t-5">
                                            <input className="input100" type="password" value={this.state.old_password} onChange={this.handleChange} name="old_password" placeholder="Current Password"/>
                                            <span className="focus-input100"></span>
                                        </div>					 */}

                      <div style={{ margin: '10px' }}>
                        <Input
                          className="input100"
                          type="password"
                          value={this.state.new_password}
                          onChange={this.handleChange}
                          name="new_password"
                          required
                          placeholder="New Password"
                        />
                      </div>

                      <div style={{ margin: '10px' }}>
                        <Input
                          className="input100"
                          type="password"
                          value={this.state.c_password}
                          onChange={this.handleChange}
                          name="c_password"
                          required
                          placeholder="Confirm New Password"
                        />
                        <p
                          className={this.state.displaytext + ' text-danger'}
                          style={{
                            fontSize: '10x',
                            color: 'green',
                          }}
                        >
                          {this.state.c_passwordErrorMessage}
                        </p>
                        <span className="focus-input100"></span>
                      </div>
                      {this.state.showError && (
                        <div
                          style={{
                            textAlign: 'center',
                            fontSize: '13px',
                            color: 'red',
                          }}
                        >
                          <span className="focus-input100">
                            {this.state.errorMessage}
                          </span>
                        </div>
                      )}
                      <div style={{ paddingTop: '30px', textAlign: 'center' }}>
                        <div className="container-login100-form-btn">
                          <Button
                            bg={'brand.blue'}
                            type="submit"
                            _hover={{
                              background:
                                'transparent linear-gradient(180deg, #2469F5 100%, #12357B 0%) 0% 0% no-repeat padding-box',
                            }}
                            onClick={this.forgotPassword}
                          >
                            Reset Password
                          </Button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              )}
            </div>
          </div>
        </section>
      </div>
    )
  }
}
const mapStateToProps = state => {
  return {
    auth: state.mainReducer.auth,
  }
}
export default connect(mapStateToProps)(ResetPassword)
