export default (function () {
  function _setAuthInfo(authInfo) {
    if (authInfo) {
      localStorage.setItem('student_authInfo', JSON.stringify(authInfo))
      localStorage.setItem('student_token', authInfo.token)
    }
  }
  function _getAuthInfo() {
    return JSON.parse(localStorage.getItem('student_authInfo'))
  }
  function _getAuthToken() {
    return localStorage.getItem('student_token')
  }
  function _clearStorage() {
    localStorage.removeItem('student_token')
    localStorage.removeItem('student_authInfo')
    localStorage.removeItem('student_selectedCourse')
  }
  function _setSelectedCourseId(courseId) {
    localStorage.setItem('student_selectedCourse', courseId.toString())
  }
  function _getSelectedCourseId() {
    return localStorage.getItem('student_selectedCourse')
  }
  return {
    setAuthInfo: _setAuthInfo,
    getAuthInfo: _getAuthInfo,
    getAuthToken: _getAuthToken,
    clearStorage: _clearStorage,
    setSelectedCourseId: _setSelectedCourseId,
    getSelectedCourseId: _getSelectedCourseId,
  }
})()
