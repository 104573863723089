import '@fontsource/open-sans'
import '@fontsource/poppins'

import { extendTheme } from '@chakra-ui/react'

const breakpoints = {
  sm: '320px',
  md: '768px',
  lg: '960px',
  xl: '1440px',
  '2xl': '1920px',
}

const theme = extendTheme({
  breakpoints,
  fonts: {
    body: 'poppins',
    heading: 'poppins',
  },
  colors: {
    brand: {
      blue: 'transparent linear-gradient(180deg, #2469F5 0%, #12357B 100%) 0% 0% no-repeat padding-box',
    },
    lightBlue: '#168AFF',
    darkBlue: '#2367f0',
    lightGreen: '#339733',
    red: {
      500: '#FFD6D6',
      600: '#FF0F0F',
    },
    lightGray: {
      300: '#CBD5E0',
      500: '#FAF9FF',
      600: '#F1EFFC',
    },
  },
  components: {
    Progress: {
      baseStyle: {
        filledTrack: {
          bg: '#2367f0',
        },
      },
    },
    Tag: {
      baseStyle: {
        filledTrack: {
          intermediate: '#FD9900 0% 0% no-repeat padding-box',
          advanced: '#2469F5 0% 0% no-repeat padding-box',
          fluent: '#9A4D03 0% 0% no-repeat padding-box',
          reds: 'green',
          beginners: '#339733 0% 0% no-repeat padding-box',
        },
      },
    },
    Button: {
      baseStyle: {
        borderRadius: '10px',
        color: 'white',
        width: '150px',
        height: '48px',
      },
      variants: {
        plain: {
          bgColor: 'white',
          color: 'black',
        },
        primary: {
          bg: 'transparent linear-gradient(180deg, #2469F5 0%, #12357B 100%) 0% 0% no-repeat padding-box',
          color: 'white',
          _hover: {
            _disabled: {
              bg: 'transparent linear-gradient(180deg, #2469F5 0%, #12357B 100%) 0% 0% no-repeat padding-box',
            },
          },
        },
        secondary: {
          bgColor: 'white',
          color: 'gray',
          border: '2px solid gray',
        },
      },
    },
    Heading: {
      variants: {
        medium: {
          fontWeight: '400',
        },
      },
    },
    Modal: {
      baseStyle: props => ({
        dialog: {
          maxWidth: [props.maxWidth ? props.maxWidth : '30%'],
          minWidth: props.maxWidth ? props.maxWidth : '30%',
          bg: '#fff',
        },
      }),
    },

    Select: {
      baseStyle: {
        borderRadius: '24px',
      },
    },
    Radio: {
      variants: {
        primary: ({ colorScheme = 'primary' }) => ({
          color: `2469F5`,
          control: {
            _checked: {
              color: '2469F5',
            },
          },
        }),
      },
      defaultProps: {
        variant: 'primary',
        colorScheme: 'primary',
      },
    },
  },
})

export default theme
