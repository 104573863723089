import { useState } from 'react'
import { useHistory } from 'react-router'
import sectionfourone from 'src/asset/images/sectionfour/sectionfourone.jpg'
import sectionfourthree from 'src/asset/images/sectionfour/sectionfourthree.jpg'
import sectionfourtwo from 'src/asset/images/sectionfour/sectionfourtwo.jpg'

import { isMobile } from '../CourseDetail/Practice/Util'
import ImageSlider from './ImageSlider'
import {
  btnHover,
  fontMain,
  fontSecond,
  hyperTextColor,
  imageFlex,
  imagelg,
  imagemd,
  imageSx,
  textPanelWidth,
} from './util'

const { Box, Flex, Text, Button } = require('@chakra-ui/react')

const selctionFourData = [
  {
    image: sectionfourone,
  },
  {
    image: sectionfourtwo,
  },
  {
    image: sectionfourthree,
  },
]
const height = '40px'
const width = isMobile ? '180px' : '150px'
const SectionFour = () => {
  const history = useHistory()
  const [autoPlay, setAutoPlay] = useState(false)
  const onSignUp = () => {
    history.push({
      pathname: '/register',
      state: {
        comingFromHome: true,
      },
    })
  }
  return (
    <Flex
      flex={'1 0 100%'}
      section={'1'}
      bg="#fff"
      w={'100%'}
      direction={['column', 'column-reverse', 'row']}
      px={[5, 5, 5]}
      py={5}
      justifyContent={'center'}
    >
      <Box px={[10, 2, 3]} py={[10, 2, 1]} mr={'20px'} w={textPanelWidth}>
        <Text textAlign={'left'} pb={0} fontSize={fontMain} fontWeight={900}>
          {"We know you'r busy, no worries!"}
        </Text>
        <Text textAlign={'left'} fontSize={fontMain} fontWeight={600}>
          <Text fontWeight={600} pt={2} fontSize={fontSecond}>
            {
              'You can access our program on all devices anywhere, anytime with an internet connection.'
            }
          </Text>
        </Text>
        {isMobile && (
          <>
            <Text
              textAlign={'left'}
              pt={3}
              fontSize={fontSecond}
              fontWeight={600}
            >
              {
                'Our learning program is made in such a way that you can learn while you are traveling on the bus or train sitting at a café, or laying on your bed.'
              }
            </Text>{' '}
          </>
        )}
        {!isMobile && (
          <>
            <Text
              textAlign={'left'}
              fontSize={fontSecond}
              fontWeight={600}
              py={3}
            >
              {
                'Our learning program is made in such a way that you can learn while you are traveling on the bus or train sitting at a café, or laying on your bed.'
              }
            </Text>
          </>
        )}
        <Box pt={[5, 5, 3]} textAlign={['center', 'center', 'inherit']}>
          <Button
            color="#707070"
            w={width}
            h={height}
            //bg={btnBg}
            style={{
              border: '1px solid #707070',
              backgroundColor: '#fff',
            }}
            borderRadius={'5px'}
            _hover={btnHover}
            onClick={onSignUp}
          >
            Sign up Now
          </Button>
        </Box>
      </Box>
      <Box
        textAlign={['center', 'left', 'left']}
        w={['90%', '100%', '30%']}
        flex={imageFlex}
        py={5}
      >
        <ImageSlider
          px={[10, 0, 0]}
          sx={[imageSx, imagemd, imagelg]}
          borderRadius={'15px'}
          slides={selctionFourData}
          autoPlay={autoPlay}
          onClick={() => {
            setAutoPlay(!autoPlay)
          }}
        ></ImageSlider>
      </Box>
    </Flex>
  )
}
export default SectionFour
