import { Box, Flex, HStack } from '@chakra-ui/react'
import Lottie from 'lottie-react'
import React, { useEffect, useRef } from 'react'
import fastAudioData from 'src/asset/lottie-json/fast_audio.json'
import tortoiseData from 'src/asset/lottie-json/tortoise.json'

import { getFileUrl } from '../Util'

const ListenAudio = React.memo(
  ({ data, containerProps, primaryIconProp, secondaryIconProp }) => {
    const audioRef = useRef()
    const fastAudioRef = useRef()
    const slowAudioRef = useRef()

    useEffect(() => {
      onAudioPlay(data?.audio)
      return () => {
        if (audioRef.current) {
          audioRef.current.pause()
          audioRef.current = null
        }
      }
    })

    const handleAudioStop = () => {
      fastAudioRef.current?.stop()
      slowAudioRef.current?.stop()
    }

    const onAudioPlay = async url => {
      if (!url) return

      try {
        if (audioRef.current) {
          audioRef.current.pause()
        }
        const audio = new Audio(getFileUrl(url))
        audio.addEventListener('ended', handleAudioStop)
        audio.play()
        audioRef.current = audio
      } catch (error) {
        console.log(error)
      }
    }

    const isAudioAvailable =
      data?.audio && data?.audio !== 'undefined' && data?.audio !== 'null'
    const isSlowAudioAvailable =
      data?.audioSlow &&
      data?.audioSlow !== 'undefined' &&
      data?.audio !== 'null'
    return (
      <Flex
        justifyContent="center"
        mb={3}
        alignItems="center"
        {...containerProps}
      >
        <HStack gap="5px">
          {isAudioAvailable && (
            <Box
              width={['70px', '70px', '80px']}
              height={['70px', '70px', '80px']}
              style={{
                border: '0.5px solid lightGray',
                borderRadius: '5px',
                cursor: 'pointer',
                padding: 10,
              }}
              onClick={() => {
                fastAudioRef.current?.play()
                onAudioPlay(data?.audio)
              }}
            >
              <Lottie
                animationData={fastAudioData}
                autoplay={false}
                lottieRef={fastAudioRef}
              />
            </Box>
          )}
          {isSlowAudioAvailable && (
            <Box
              width={['70px', '70px', '80px']}
              height={['70px', '70px', '80px']}
              style={{
                border: '0.5px solid lightGray',
                borderRadius: '5px',
                cursor: 'pointer',
              }}
              onClick={() => {
                slowAudioRef.current?.play()
                onAudioPlay(data?.audioSlow)
              }}
            >
              <Lottie
                animationData={tortoiseData}
                autoplay={false}
                lottieRef={slowAudioRef}
              />
            </Box>
          )}
        </HStack>
      </Flex>
    )
  }
)

export default ListenAudio
