import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Flex,
  Input,
  InputGroup,
  InputLeftAddon,
  Text,
  useToast,
} from '@chakra-ui/react'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { useUser } from 'src/hooks/useUser'
import { updateUserPassword } from 'src/redux/actions'
import { useFlags } from 'src/redux/useFeatureFlags'
import API from 'src/util/api'
import Config from 'src/util/config'

import RzpUserSubscription from './RzpUserSubscription'
import UserSubscription from './UserSubscription'

const UserProfile = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const loading = useSelector(store => store.mainReducer.loading)
  const toast = useToast()

  const [newPassword, setNewPassword] = useState()
  const [confirmPassword, setConfirmPassword] = useState()
  const [errorMessage, setErrorMessage] = useState('')
  const { user: auth } = useUser()

  const { RAZORPAY_ENABLED } = useFlags()

  const onSubmit = () => {
    setErrorMessage('')
    if (!newPassword || newPassword.trim().length < 5) {
      setErrorMessage('A minimum 5 characters new password contains')
    } else if (newPassword !== confirmPassword) {
      setErrorMessage('Confirm password and new password should be same')
    } else {
      dispatch(
        updateUserPassword({
          email: `${auth.email}`,
          password: newPassword,
        })
      )
      setNewPassword('')
      setConfirmPassword('')
      toast({
        title: 'Success',
        description: 'Password changed successfully.',
        status: 'success',
        duration: 2000,
        isClosable: true,
      })
    }
  }

  if (!auth) {
    history.push('/login')
  }
  const onGetActivationLink = async () => {
    toast.closeAll()
    const response = await API.post(Config.API_URI + 'getActivationLink', {
      email: auth.email,
      url: window.location.origin,
    })
    if (response) {
      toast({
        status: 'success',
        title: 'You have successfully sent activation link your account',
        position: 'bottom-left',
        isClosable: true,
        containerStyle: {
          fontSize: '14px',
        },
      })
    } else {
      toast({
        status: 'warning',
        title:
          'we are unable to get your email address, please try after sometime.',
        position: 'bottom-left',
        isClosable: true,
        containerStyle: {
          fontSize: '14px',
        },
      })
    }
  }
  return (
    <>
      <Flex justifyContent={'center'}>
        <Text fontSize={18} fontWeight={'bold'} p={5}>
          {`Profile Details: ${auth.firstName} ${auth.lastName}`}
        </Text>
      </Flex>
      <Flex w="98%" mx="auto" gap="2" paddingTop={'10px'}>
        <Accordion allowToggle defaultIndex={[0]}>
          <AccordionItem>
            <AccordionButton>
              <Box textAlign="left" w={'90vw'}>
                Personal Information
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel>
              <Box>
                <InputGroup>
                  <InputLeftAddon
                    p={0}
                    justifyContent="center"
                    children="Name"
                    w={'100px'}
                  />
                  <Input
                    readOnly
                    placeholder="userName"
                    value={`${auth.firstName} ${auth.lastName}`}
                  />
                </InputGroup>
                <InputGroup pt={2}>
                  <InputLeftAddon
                    p={0}
                    justifyContent="center"
                    children="Username"
                    w={'100px'}
                  />
                  <Input
                    readOnly
                    placeholder="username"
                    value={`${auth.username}`}
                  />
                </InputGroup>
                <InputGroup pt={2}>
                  <InputLeftAddon
                    p={0}
                    justifyContent="center"
                    children="Email"
                    w={'100px'}
                  />
                  <Input readOnly placeholder="email" value={`${auth.email}`} />
                </InputGroup>
                <InputGroup pt={2}>
                  <InputLeftAddon
                    p={0}
                    justifyContent="center"
                    children="Phone"
                    w={'100px'}
                  />
                  <Input readOnly placeholder="phone" value={`${auth.phone}`} />
                </InputGroup>
                <InputGroup pt={2}>
                  <InputLeftAddon
                    p={0}
                    justifyContent="center"
                    children="City"
                    w={'100px'}
                  />
                  <Input readOnly placeholder="city" value={`${auth.city}`} />
                </InputGroup>
                <InputGroup pt={2}>
                  <InputLeftAddon
                    p={0}
                    justifyContent="center"
                    children="State"
                    w={'100px'}
                  />
                  <Input readOnly placeholder="state" value={`${auth.state}`} />
                </InputGroup>
                <InputGroup pt={2}>
                  <InputLeftAddon
                    p={0}
                    justifyContent="center"
                    children="Country"
                    w={'100px'}
                  />
                  <Input
                    readOnly
                    placeholder="country"
                    value={`${auth.country}`}
                  />
                </InputGroup>
                <InputGroup pt={2}>
                  <InputLeftAddon
                    p={0}
                    justifyContent="center"
                    children="Status"
                    w={'100px'}
                  />
                  <Input
                    readOnly
                    placeholder="status"
                    value={`${auth.isActive ? 'Active' : 'Disable'}`}
                  />
                </InputGroup>
                <Box textAlign={'center'} p={3}>
                  {auth.isNotValidated && (
                    <Button
                      ml="auto"
                      size="lg"
                      width={'300px'}
                      isLoading={loading}
                      justifyContent={'center'}
                      variant="primary"
                      onClick={onGetActivationLink}
                    >
                      Get Account Activation Link
                    </Button>
                  )}
                </Box>
              </Box>
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <AccordionButton>
              <Box textAlign="left" w={'90vw'}>
                Change Password
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel>
              <InputGroup>
                <InputLeftAddon
                  p={0}
                  justifyContent="center"
                  children="New Password"
                  w={'180px'}
                />
                <Input
                  placeholder="New Password"
                  type={'password'}
                  minLength={5}
                  value={newPassword}
                  onChange={e => setNewPassword(e.currentTarget.value.trim())}
                />
              </InputGroup>
              <InputGroup pt={3}>
                <InputLeftAddon
                  p={0}
                  justifyContent="center"
                  children="Confirm Password"
                  w={'180px'}
                />
                <Input
                  placeholder="Confirm Password"
                  type={'password'}
                  minLength={5}
                  value={confirmPassword}
                  onChange={e =>
                    setConfirmPassword(e.currentTarget.value.trim())
                  }
                />
              </InputGroup>

              {errorMessage.length > 0 && (
                <Text textAlign={'center'} p={2} color={'red'}>
                  {errorMessage}
                </Text>
              )}
              <Box textAlign={'center'} p={3}>
                <Button
                  ml="auto"
                  size="lg"
                  isLoading={loading}
                  justifyContent={'center'}
                  variant="primary"
                  onClick={onSubmit}
                >
                  Submit
                </Button>
              </Box>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionButton>
              <Box textAlign="left" w={'90vw'}>
                Subscription Information
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel>
              {RAZORPAY_ENABLED ? (
                <RzpUserSubscription />
              ) : (
                <UserSubscription />
              )}
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </Flex>
    </>
  )
}

export default UserProfile
