import { Box, Center, Flex, Image, Text } from '@chakra-ui/react'
import Lottie from 'lottie-react'
import React, { useRef, useState } from 'react'
import audio from 'src/asset/images/audio.png'
import waveData from 'src/asset/lottie-json/audio_wave.json'

import ListenAudio from '../HelperComponents/ListenAudio'
import useMediaOptions from './useMediaOptions'

function ListenWithAudio({ exercise, setSelectedAnswer }) {
  const lottieRef = useRef()

  const [selectedMedia, setSelectedMedia] = useState({})
  const { mediaList, onAudioPlay } = useMediaOptions({
    exercise,
    hasAudio: true,
    onAudioStop: () => {
      lottieRef.current?.stop()
    },
  })

  const onAnswerClick = media => {
    setSelectedMedia(media)
    onAudioPlay(media.value)
    setSelectedAnswer(media)
  }

  return (
    <>
      {(exercise?.audio || exercise?.slowAudio) && (
        <ListenAudio data={exercise} />
      )}
      {/* todo: make this a common component */}
      <Box pt={[5, 5, 20]}>
        <Flex
          direction={['column', 'column', 'row']}
          alignItems="center"
          justifyContent={'center'}
          gap={10}
        >
          {mediaList.map((media, i) => (
            <Center
              variant="plain"
              onClick={() => onAnswerClick(media)}
              border="solid lightgray 1px"
              borderRadius={'10px'}
              w={'200px'}
              h="70px"
              cursor={'pointer'}
              boxShadow={selectedMedia === media ? '2px 5px #2367f0' : ''}
              key={media.key + i}
            >
              {selectedMedia === media ? (
                <Lottie
                  animationData={waveData}
                  loop={true}
                  lottieRef={lottieRef}
                />
              ) : (
                <>
                  <Image mr={3} boxSize={'20px'} src={audio} />{' '}
                  <Text fontSize="xs">Audio {i + 1}</Text>
                </>
              )}
            </Center>
          ))}
        </Flex>
      </Box>
    </>
  )
}

export default ListenWithAudio
